
import { withErrorBoundary } from "../../../shared-components/ErrorBoundary";
import { CampaignHeader } from './EditCamapaignHeader';
import { EditCampaignTabs } from './EditCampaignTabs';
import informationConfig from '../../../shared-content/inputDescription';
import { InformationText } from '../../../shared-components/info-text/InformationText';
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../root-redux/RootState";
import { Attribute, Campaign } from "../../../model/SPAModels/campaigns/campaign.Model";
import { useDispatch } from "react-redux";
import { WorkflowSummary } from "../../../model/workflows/Workflow.model";
import { v4 as uuidv4 } from 'uuid'
import { DataIngestion } from "../../../model/workflows/DataIngestion.model";
import { addSPADataSet, ingestCartQueryAndFinalView, ingestFinalView, setStandardSPAFlag, updateCampaignPublish, updateCurrentStage, updatePublishAttributes } from "../redux/CampaignById.redux";
import { CampaignPublish } from "./CampaignPublish";
import { getConnections } from "../../../services/GetConnections.api";
import { connectionsSelector } from "../redux/Campaign.selector.redux";
import { CampaignStageType } from "../../../model/SPAModels/campaigns/CampaignStageType.Model";
import { EditCampaignSideBar } from "./EditCampaignSideBar";
import { EditCampaignFooter } from "./EditCampaignFooter";
import { Button, Card, CheckBox, CheckboxGroup, ProgressRing, Text } from "../SpaAdminScope";
import { Initialize } from "../utils/Initialize";
import { ATTRIBUTE_ORG, ATTRIBUTE_REFERENCE_ID_KEY, ATTRIBUTE_SEGMENT, AuditFieldProperties, CAMPAIGN_AUDIT_COLUMN_NEW_PREFIX, CampaignAuditPublishParameter, CampaignFields, CampaignFieldsForPublish, CampaignPublishParameter, CampaignPublishTemplate, CampaignStatusEnum, CampaignTypeToBlobConnectionMap, CampaignTypes, CampaignTypesEnum, ColumnsToBeRemovedFromFinalView, DataTypes, DefaultPublishValues, FieldProperties, MsxLinks, OrgTypesEnum, PublishTemplatePropertyTypes, SPA_CAMPAIGN, SPA_CAMPAIGN_TEMPLATE_TYPE, SPA_CAMPAIGN_TYPE_ATTRIBUTE, UPPER_CASE, dataSetPrimaryFieldsForPublishMap } from "../SPAConstants";
import { PublishMap, PublishModel } from "../../../model/workflows/Publish.model";
import { Helper } from "../utils/Help";
import PublishTemplate from "../utils/CampaignPublishTemplate.json"
import { config } from "process";
import { RequestStatus } from "../../../model/ServiceRequestStatus.model";
import { NavMenuItemType } from "../../../model/NavMenu.model";
import { useNavigate } from "react-router";
import { Processing } from "../../../model/workflows/Processing.model";

const CampaignIngestFinalViewC: React.FC = () => {

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const isNavPanelOpen = useSelector((state: RootState) => state.nav.isNavPanelOpen);
  const isSideNavOpen = useSelector((state: RootState) => state.campaignById.isSideNavOpen)
  const campaignRequestStatus = useSelector((state: RootState) => state.campaignById.requestStatus)
  const subscriptionKey = useSelector((state: RootState) => state.campaignById.campaign!.subscriptionKey)
  const selectedEnv = useSelector((state: RootState) => state.env.selectedEnvItem)

  const datasetconnName = "campaign-nebula-modeldataset"; //TO-DO standard name 
  const campaignConnName ="campaign-nebula";
  const campaignAuditHistoryConnName ="campaign-nebula-audithistory"; //TO-DO standard name

  useEffect(() => {
    // fetch connections on component load
    var subKeysArr: Array<string> = [subscriptionKey];
    dispatch(getConnections({ subscriptionKeys: subKeysArr }));
  }, [dispatch]);

  const campaignById = useSelector(
    (state: RootState) => state.campaignById
  );
  const isStandardSPA = CampaignTypes.includes(campaignById.summary['CampaignType'] || "");

  const [currentStage, setCurrentStage] = useState<number>(CampaignStageType.Publish);
  const campaign = useSelector((state: RootState): Campaign => state.campaignById.campaign || {} as Campaign);

  const currentPublishAttributes = useSelector((state: RootState) => state.campaignById.publishAttributes);
  const userSalesRoles = useSelector((state: RootState) => state.userSalesRoles);

  const workflows = useSelector((state: RootState): WorkflowSummary[] => state.workflowsBySubKeys.list);

  const { connections, appScopes } = useSelector(connectionsSelector);

  const [publishAttributesList, setPublishAttributesList] = useState<[string, string][]>([]);
  const [dataSetattributesList, setdataSetattributesList] = useState<{ [key: string]: string }>({});
  
  const [certCheck, setCertCheck] = useState<any>({});

  const _onNavMenuItemClick = useCallback((navMenuItemType: NavMenuItemType, path: string | undefined = undefined) => {
    navigate("/" + NavMenuItemType[navMenuItemType] + (path ? `/${path}` : ''));
  }, [navigate]);

  if (campaignRequestStatus === RequestStatus.idle) {
    _onNavMenuItemClick(NavMenuItemType.Configuration);
  }


  //  const campaignId = "2f81acfa-c5c2-4388-a16f-09d45e6cb03a";//TO-DO once the edit flow is available , we can remove this .
  const getPublishParametersByType = (type: string): CampaignPublishParameter | undefined => {
    let campPubParam: CampaignPublishParameter | undefined = undefined
    if (type !== "N/A") {
      const pubTemplate: CampaignPublishTemplate = JSON.parse(JSON.stringify(PublishTemplate));
      campPubParam = pubTemplate.campaignPublishParameters.find((pubParam: CampaignPublishParameter) => pubParam?.type?.toLowerCase() === type.toLowerCase());
    }
    return campPubParam;
  }
  const getCampaignAuditParametersByType = (type: string): CampaignAuditPublishParameter | undefined => {
    let campPubAuditParam: CampaignAuditPublishParameter | undefined = undefined
    if (type !== "N/A") {
      const pubTemplate: CampaignPublishTemplate = JSON.parse(JSON.stringify(PublishTemplate));
      campPubAuditParam = pubTemplate.campaignAuditPublishParameters.find((pubParam: CampaignAuditPublishParameter) => pubParam?.type?.toLowerCase() === type.toLowerCase());
    }
    return campPubAuditParam;
  }
  const campaignPublishParametersFromTemplate: CampaignPublishParameter | undefined = isStandardSPA ? getPublishParametersByType(SPA_CAMPAIGN_TEMPLATE_TYPE) : undefined;
  const campaignAuditPublishParametersFromTemplate: CampaignAuditPublishParameter | undefined = isStandardSPA ? getCampaignAuditParametersByType(SPA_CAMPAIGN_TEMPLATE_TYPE) : undefined;

  const getAllPrimaryFieldsFromTemplate = (): string[] => {
    let primaryFields: string[] = [];
    if (campaignPublishParametersFromTemplate) {
      campaignPublishParametersFromTemplate.publishDataSetFieldsList?.forEach((field: FieldProperties) => {
        if (field.isPrimary) {
          primaryFields.push(field.name);
        }
      })
      campaignPublishParametersFromTemplate.publishSummaryFieldsList?.forEach((field: FieldProperties) => {
        if (field.isPrimary) {
          primaryFields.push(field.name);
        }
      })
    }
    return primaryFields;
  }
  const getAllEditableCampaignFieldsFromAuditTemplate = (): AuditFieldProperties[]|undefined => {
    let editableFields: AuditFieldProperties[] = [];
    return campaignAuditPublishParametersFromTemplate?campaignAuditPublishParametersFromTemplate.publishAuditParametersList:editableFields;
  }
  
  const getAllEditableFieldNamesFromAuditTemplate = (prefixNeeded:boolean): string[] => {
    let editableFields: string[] = [];
    if(campaignAuditPublishParametersFromTemplate){
      campaignAuditPublishParametersFromTemplate.publishAuditParametersList?.forEach((field: AuditFieldProperties) => {
        if (field.canBeUpdated) {
          prefixNeeded? editableFields.push(CAMPAIGN_AUDIT_COLUMN_NEW_PREFIX+field.name):editableFields.push(field.name);
        }
      })
    }
    return editableFields;
  }
  const getAllMatchingMandatoryFieldMappings = (): FieldProperties[] => {
    let mandatoryFields: FieldProperties[] = [];
    if (campaignPublishParametersFromTemplate) {

      campaignPublishParametersFromTemplate.publishDataSetFieldsList?.forEach((field: FieldProperties) => {
        if (field.matchingMandatoryFieldMapping) {
          mandatoryFields.push(field);
        }
      })
      campaignPublishParametersFromTemplate.publishSummaryFieldsList?.forEach((field: FieldProperties) => {
        if (field.matchingMandatoryFieldMapping) {
          mandatoryFields.push(field);
        }
      })
    }
    return mandatoryFields;
  }
 
  const getFieldsFromTemplateByPropertyType = (property:string): FieldProperties[] => {
    let fieldsByTpe: FieldProperties[] = [];
    if (campaignPublishParametersFromTemplate) {
      switch (property) {
        case PublishTemplatePropertyTypes.Alias:campaignPublishParametersFromTemplate.publishDataSetFieldsList?.forEach((field: FieldProperties) => {
          if (field.alias) {
            fieldsByTpe.push(field);
          }
        })
        break;
        case PublishTemplatePropertyTypes.MatchingMandatoryFieldMapping:
          campaignPublishParametersFromTemplate.publishDataSetFieldsList?.forEach((field: FieldProperties) => {
          if (field.matchingMandatoryFieldMapping) {
            fieldsByTpe.push(field);
          }
        })
        campaignPublishParametersFromTemplate.publishSummaryFieldsList?.forEach((field: FieldProperties) => {
          if (field.matchingMandatoryFieldMapping) {
            fieldsByTpe.push(field);
          }
        })
        break;
        case PublishTemplatePropertyTypes.Optional:
          campaignPublishParametersFromTemplate.publishDataSetFieldsList?.forEach((field: FieldProperties) => {
            if (field.isOptional) {
              fieldsByTpe.push(field);
            }
          })
          break;
      }
    }
      return fieldsByTpe;
  }
  const campaignSummaryFields: string[] = [];



  const primaryFields: string[] = getAllPrimaryFieldsFromTemplate();
  const matchingMandatoryFieldMappings: FieldProperties[] = getAllMatchingMandatoryFieldMappings();
  const aliasForAttributesFromTemplate = getFieldsFromTemplateByPropertyType(PublishTemplatePropertyTypes.Alias);
  const optionalAttributesFromTemplate = getFieldsFromTemplateByPropertyType(PublishTemplatePropertyTypes.Optional);

  useEffect(() => {
    //Create the view
    /**
     * 1. Get the campaign fields to be part of the view (campaigntags,campaignname,DueDate,ExpiryDate,SolutionArea,Salesplay,SalesMotion,Product)
     * 2. Get the source fields which are not part of campaign fields from above step
     * 3. Get the source data fields (TPID,RecText,ReasonText,EstimatedRev)
     * 4. Create the view with the above fields
     * 5. Get the connectionid for the cosmos model dataset
     * 6. Update PostProcessing step with connid and query and post campaign
     * 
     */
    if (campaign && campaign.id && workflows && workflows.length > 0 && publishAttributesList.length === 0
      && connections && connections.cosmosConnections && connections.cosmosConnections.length > 0
    ) {


      //Get all campaign summary fields
      campaign.summary.forEach((attr: Attribute) => {
        var key = attr.key;
        if (!campaignPublishParametersFromTemplate || !campaignPublishParametersFromTemplate.publishSummaryFieldsList ) {
          campaignSummaryFields.push(key);
          publishAttributesList.push([key, DataTypes.AttributeValueType]);
        }else {
          //Use the key from template if available
          var fieldName =  getKeyFieldNameFromTemplate(key, campaignPublishParametersFromTemplate.publishSummaryFieldsList)
          if(fieldName){
            campaignSummaryFields.push(fieldName);
            publishAttributesList.push([fieldName, DataTypes.AttributeValueType]);
          }
        }
       
      });
      //Get all unique fields from all the used model data srces, if any template available for dataset fields use that data else push everything
      campaign.dataSets.forEach((modelDatasrcId) => {
        var workflowSummary = workflows.find(obj => obj.config.workflowId.toLowerCase() === modelDatasrcId.toLowerCase());
        var fields: { [key: string]: string } = workflowSummary?.fields;
        for (const [key, value] of Object.entries(fields)) {
          if (!campaignPublishParametersFromTemplate || !campaignPublishParametersFromTemplate.publishDataSetFieldsList  || checkIfExists(key, Object.keys(dataSetPrimaryFieldsForPublishMap))) { //For SPA            dataSetattributesList[key] = value;
            dataSetattributesList[key] = value;
            publishAttributesList.push([key, value]);
          }else {
            var fieldName =  getKeyFieldNameFromTemplate(key, campaignPublishParametersFromTemplate.publishDataSetFieldsList)
            if(fieldName){
              dataSetattributesList[fieldName] = value;
              publishAttributesList.push([fieldName, value]);
            }            
          }
        }
      })

      let viewSelectQuery = 's""" SELECT DISTINCT c.Source.SourceId as SourceId,';
      let fromQuery = " FROM c ";
      let attAlias = "att";
      let whereQuery = " WHERE "
      let viewCampaignFieldsQuery = " '"+campaign.config.workflowName+"' as "+CampaignFields.CampaignName+", '"+campaign.config.workflowId+"' as "+CampaignFields.WorkflowId+", '"+campaign.version+"' as "+CampaignFields.Version;
      publishAttributesList.push([CampaignFields.CampaignName, DataTypes.AttributeValueType]);
      publishAttributesList.push([CampaignFields.WorkflowId, DataTypes.AttributeValueType]);
      publishAttributesList.push([CampaignFields.Version, DataTypes.AttributeValueType]);

      let viewCampaignJoinQuery = "";

      //Get all Campaign fields
      CampaignFieldsForPublish.forEach((field) => {
        viewCampaignFieldsQuery += " ,'" + campaign[field[1] as keyof Campaign] + "' as " + field[0];
        publishAttributesList.push([field[0], DataTypes.AttributeValueType]);
      });

      campaignSummaryFields.forEach((summAttrField) => {
        campaign.summary.find((attr: Attribute) => {
          if (attr.key.toLowerCase() == summAttrField.toLowerCase()) {
            viewCampaignFieldsQuery += " ,'" + attr.value + "' as " + attr.key
          }
        })
      })

            whereQuery += " upper(c.Source.SourceId) IN (" + getAllIdsInQuotes(campaign.dataSets,"'", UPPER_CASE) + ")"
       + " AND EXISTS (SELECT VALUE 1 FROM att IN c.Attributes WHERE att['Key'] ='" + ATTRIBUTE_REFERENCE_ID_KEY + "' and att['Value'] IN ${refIdsString})";

    

       //Check whether all optional fields are there in the datasetAttributeList.If not add them exclusively to dataSetattributesList
       //Fields available in Customfile but not in ML model and vice versa are added exclusively to get them in the o/p file for SPA
       optionalAttributesFromTemplate.forEach((field: FieldProperties) => {
        if (!checkIfExists(field.name, Object.keys(dataSetattributesList))) {
          dataSetattributesList[field.name] = field.name;
          publishAttributesList.push([field.name, DataTypes.AttributeValueType]);
        }

      });

      //Get a select and where clause for each dataset attribute 
      for (const [key, value] of Object.entries(dataSetattributesList)) {

        if(checkIfExists(key, aliasForAttributesFromTemplate.map((field: FieldProperties) => field.name)))
        {
          var fieldAlias = aliasForAttributesFromTemplate.find((field: FieldProperties) => field.name.toLowerCase() === key.toLowerCase())?.alias;
          viewCampaignFieldsQuery += ", FIRST( SELECT VALUE n['Value'] FROM n IN c.Attributes WHERE  n['Key'] ="+ "'"+key+"'"+") ?? '' AS "+fieldAlias;
        }
        else {
        
         viewCampaignFieldsQuery += ", FIRST( SELECT VALUE n['Value'] FROM n IN c.Attributes WHERE  n['Key'] ="+ "'"+key+"'"+") ?? '' AS "+ key;
        }
      }
      var finalViewQuery = viewSelectQuery + viewCampaignFieldsQuery + fromQuery + viewCampaignJoinQuery + whereQuery+'"""';
      console.log('finalViewQuery ' + finalViewQuery);

      //Get the audit history fields to be part of the view (SourceId,ReferenceId,_ts,OldValue,NewValue,FieldName)
      var auditHistoryFieldsQuery:string = "";
      // getAllEditableCampaignFieldsFromAuditTemplate().forEach((field) => {

      //   auditHistoryFieldsQuery += " ,aud.NewValue + "' as ;
      // });
      var datasetConnId = connections?.cosmosConnections?.filter(
        (item) =>
          item.connectionName?.toLowerCase() === datasetconnName.toLowerCase()
      )[0]?.connectionId;

      var campaignConnId = connections?.cosmosConnections?.filter(
        (item) =>
          item.connectionName?.toLowerCase() === campaignConnName.toLowerCase()
      )[0]?.connectionId;

      var campaignAuditHistoryConnId = connections?.cosmosConnections?.filter(
        (item) =>
          item.connectionName?.toLowerCase() === campaignAuditHistoryConnName.toLowerCase()
      )[0]?.connectionId;

      
      const getCampaignInCartData: DataIngestion = {
        stepID: uuidv4().toString(),
        type: 'COSMOS',
        outputViewName: 'campaign_incart_refIds',
        path: '',
        appModelName: '',
        query: "SELECT c.InCart from c WHERE c.Config.WorkflowId = '" + campaign.config.workflowId + "'"+" AND c.Version = "+campaign.version ,
        connectionId: campaignConnId,
        inputViewName: "",
        scalaScript: ""
      }
  
      const getAllAuditHistoryRecsPerSource: DataIngestion = {
        stepID: uuidv4().toString(),
        type: 'COSMOS',
        outputViewName: 'auditHistorySourceRecs',
        path: '',
        appModelName: '',
        query: "SELECT c.SourceId,c.ReferenceId,c._ts,c.OldValue,c.NewValue,c.FieldName from c WHERE UPPER(c.SourceId) IN  ("+getAllIdsInQuotes(campaign.dataSets,"'", UPPER_CASE)+")",
        connectionId: campaignAuditHistoryConnId,
        inputViewName: "",
        scalaScript: ""
      }
      const getAuditHistoryLatestRecs: DataIngestion = {
        stepID: uuidv4().toString(),
        type: 'COSMOS',
        outputViewName: 'auditHistorySourceLatestRecs',
        path: '',
        appModelName: '',
        query: "SELECT c.SourceId,c.ReferenceId,MAX(c._ts) as max_ts from c WHERE UPPER(c.SourceId) IN  ("+getAllIdsInQuotes(campaign.dataSets,"'", UPPER_CASE)+") group by c.SourceId,c.ReferenceId",
        connectionId: campaignAuditHistoryConnId,
        inputViewName: "",
        scalaScript: ""
      }
      const finalView1: DataIngestion = {
        stepID: uuidv4().toString(),
        type: 'COSMOS',
        outputViewName: 'final_vw_campaign_withoutAudit',
        path: '',
        appModelName: '',
        query: finalViewQuery,
        connectionId: datasetConnId,
        inputViewName: "",
        scalaScript: 'spark.sql(s"""SELECT concat(\'(\', concat_ws(\',\', transform(InCart, x -> concat("\'", x, "\'"))), \')\') AS InCartString FROM campaign_incart_refIds""").first().getString(0)'
      }
      
      const auditHistorySourceRecsFinalScala = '\n'
      +'      val sqlQuery = "select src.* from auditHistorySourceRecs src inner join auditHistorySourceLatestRecs lat on src.ReferenceId = lat.ReferenceId and src.SourceId = lat.SourceId and src._ts=lat.max_ts"\n'
      +'      val auditHistorySourceRecsCount = spark.table(InputViewName).count()\n'
      +'      if (auditHistorySourceRecsCount > 0) {\n'
      +'        var QueryOutput = spark.sql(s"""$sqlQuery""".stripMargin)\n'
      +'        QueryOutput.createOrReplaceTempView(OutputViewName)\n'
      +'      } else {\n'
      +'      var QueryOutput = spark.sql(s"""select cast(null as string) as SourceId, cast(null as string) as ReferenceId, cast(null as long) as _ts,cast(null as string) as OldValue,cast(null as string) as NewValue,cast(null as string) as FieldName""".stripMargin)\n'
      +'      QueryOutput.createOrReplaceTempView(OutputViewName)\n'
      +'      }\n'
      const auditHistorySourceRecsFinal: Processing = {
        stepID: uuidv4().toString(),
        type: 'SCALA',
        inputViewName: 'auditHistorySourceRecs',
        outputViewName: 'auditHistorySourceRecsFinal',
        query:"",
        jsonQueryString: "",
        path: "",
        appModelName: "",
        scalaScript: auditHistorySourceRecsFinalScala
      }
      var dataIngArr:Array<DataIngestion> = [getCampaignInCartData,getAllAuditHistoryRecsPerSource,getAuditHistoryLatestRecs,finalView1];
      var preProcessingArr:Array<Processing>=[auditHistorySourceRecsFinal];
      getAllEditableCampaignFieldsFromAuditTemplate()?.forEach((field,index) => {
         
        var editableFieldsLength = getAllEditableCampaignFieldsFromAuditTemplate()?.length??0;
        var inputViewName = index==0?"final_vw_campaign_withoutAudit":"final_vw"+(index-1)+"_campaign";
        var outputViewName = index==editableFieldsLength-1?"final_vw_campaign_withAudit":"final_vw"+index+"_campaign";
        var query = "SELECT cam.*,aud.NewValue as "+CAMPAIGN_AUDIT_COLUMN_NEW_PREFIX+field.name+" from "+inputViewName+" cam left join auditHistorySourceRecsFinal aud on cam.SourceId = aud.SourceId and cam.ReferenceId = aud.ReferenceId and aud.FieldName = '"+field.name+"'";
        var finalViewWithAuditHistory: Processing = {
          stepID: uuidv4().toString(),
          type: 'SQL',
          inputViewName: inputViewName,
          outputViewName: outputViewName,
          query:query ,
          jsonQueryString: "",
          path: "",
          appModelName: "",
          scalaScript: ""
        }
        preProcessingArr.push(finalViewWithAuditHistory);
        publishAttributesList.push([CAMPAIGN_AUDIT_COLUMN_NEW_PREFIX+field.name, DataTypes.AttributeValueType]);
      });
      
      /**
       * This preprocessing step checks if there exists updated values in audit history for any editable columns 
       * If exits, uses the value from audit hisotry otherwise use the original value from the dataset
       * We remove SourceId and ReferenceId from the finalView because those are not needed in the final file and needed for intermediate p
         purposes to get the corresponding updated value in the audit hisotry
       * This is generic functionality applicable for any field changes but the only thing needed is to update those fields in the template file.
       **/
      var scalaQueryForFinalView = '\n // Read the view into a DataFrame\n'
      +'      val df = spark.table("final_vw_campaign_withAudit")\n'
      +'      val colsTobeRemovedFromFinalView = List('+getAllIdsInQuotes(ColumnsToBeRemovedFromFinalView,'"',"")+')\n'
      +'      val auditCols = List('+getAllIdsInQuotes(getAllEditableFieldNamesFromAuditTemplate(true),'"',"")+')\n'
      +'      val editableCampaignCols = List('+getAllIdsInQuotes(getAllEditableFieldNamesFromAuditTemplate(false),'"',"")+')\n'
      +'  // Get the schema of the DataFrame\n'
      +'      val schema = df.schema\n\n'
      +'  // Extract the column names and data types\n'
      +'      val finalViewcolumnsList = schema.fields.map(field => (field.name, field.dataType.simpleString)).toSeq.toDF("ColumnName", "DataType")\n\n'
      +'  // Select columns except those in the list\n'
      +'      val selectedColumns = finalViewcolumnsList.filter(!$"ColumnName".isin(colsTobeRemovedFromFinalView: _*)).select("ColumnName").as[String].collect().toSeq\n'
      +'      val selectedDf = df.select(selectedColumns.map(col): _*)\n\n'
      +'  // Get the value from updated if exists, otherwise keep the original value\n'
      +'      var finalDf = selectedDf\n'
      +'      editableCampaignCols.foreach(colName => {\n'
      +'      finalDf=selectedDf.withColumn(s"$colName",\n      when(col(s"New_$colName").isNotNull, col(s"New_$colName"))\n      .otherwise(col(s"$colName")))\n'
      +'      finalDf = finalDf.drop(s"New_$colName")\n'
      +'  })\n'
      +'      finalDf.createOrReplaceTempView(s"$OutputViewName")\n'
      const finalViewWithNecessaryPublishColumns: Processing = {
        stepID: uuidv4().toString(),
        type: 'SCALA',
        inputViewName: 'final_vw_campaign',
        outputViewName: 'final_vw_campaign',
        query: "",
        jsonQueryString: "",
        path: "",
        appModelName: "",
        scalaScript: scalaQueryForFinalView
      }
      preProcessingArr.push(finalViewWithNecessaryPublishColumns);
      dispatch(ingestCartQueryAndFinalView([dataIngArr,preProcessingArr]));
      dispatch(updatePublishAttributes(publishAttributesList));



      if (isStandardSPA) { // TO-DO Check only if there is any change so far?
        const publishMap: PublishMap[] = [];
        publishAttributesList.forEach((attribute => {
          //Check whether this attribute is part of Mandatory publish fields and get Corresponding matching property name
          var mandatoryFieldName = getMatchingMandatoryFieldName(attribute[0]);
          publishMap.push({
            columnId: uuidv4().toString(),
            source: attribute[0],
            fieldType: attribute[1],
            destination: mandatoryFieldName ? mandatoryFieldName : attribute[0],
            mapType: "",
            sourceFunction: "",
            isPrimary: checkIfExists(attribute[0], primaryFields),
            dataRetainOnUpdate: false,
            deleteState: "",
          });
        }));

        dispatch(addSPADataSet({ publishMap: publishMap, blobConnectionId: getConnectionId(), primaryKeys: primaryFields }));
        dispatch(updateCurrentStage(CampaignStageType.Publish));
        dispatch(setStandardSPAFlag(true));
      }
    }
  }, [campaign, workflows, connections]);

  const getConnectionId = (): string => {
    var connName: string = CampaignTypeToBlobConnectionMap[campaignById.summary[SPA_CAMPAIGN_TYPE_ATTRIBUTE] as CampaignTypesEnum];
    return connections?.blobConnections?.filter(
      (item) =>
        item.connectionName?.toLowerCase() === connName.toLowerCase()
    )[0]?.connectionId || "";
  }
  const checkIfExists = (campField: string, campFields: string[]): boolean => {
    return campFields.some(field => field.toLowerCase() === campField.toLowerCase());
  };

  const checkIfFieldExists = (field: string, fieldsList: FieldProperties[]): boolean => {
    return fieldsList.some(fieldObj => fieldObj.name.toLowerCase() === field.toLowerCase());
  }

  const getKeyFieldNameFromTemplate = (field: string, fieldsList: FieldProperties[]): string | undefined => {

    const fieldProps =  fieldsList.find((fieldObj: FieldProperties) => 
      fieldObj.name.toLowerCase() === field.toLowerCase()
    );
    return fieldProps?.name??undefined;
  }
  const getMatchingMandatoryFieldName = (field: string): string | undefined => {
    let matchingMandatoryFieldName: string | undefined;
    matchingMandatoryFieldMappings.forEach((fieldObj: FieldProperties) => {
      if (fieldObj.name.toLowerCase() === field.toLowerCase()) {
        matchingMandatoryFieldName = fieldObj.matchingMandatoryFieldMapping;
      }
    })
    return matchingMandatoryFieldName;
  }

  const getAllIdsInQuotes = (ids: string[], quote:string, caseNeeded: string = ""): string => {
    return ids && ids.length > 0 ?
      ids.map((id) => {
        return quote + (caseNeeded && caseNeeded === UPPER_CASE ? id.toUpperCase() : id) + quote;
      }).join(",") : "";
  }

  const checkUserCertificate = (e: any) => {
    const temp = {...certCheck};
    temp[e.target.name] = e.target.checked;
    setCertCheck(temp);
  }

  const renderCheckBoxes = (texts: string[]) => {
    return <>
      {texts.map((text: string, index: number) => 
        <CheckBox onHeChange={checkUserCertificate} name={`check-${index}`}>{text}<span style={{color: 'red'}}> *</span></CheckBox>
      )}
    </>
  }

  if (!currentPublishAttributes || currentPublishAttributes.length === 0) {
    return (
      <div className='spa-progressring'>
        <ProgressRing label="Loading attributes..." indeterminate></ProgressRing>
      </div>
    )
  }
  else {

    return (

      <>

        <div className={`spa-sticky-header ${isNavPanelOpen ? '' : 'collapsed'}`}>
          <CampaignHeader title={campaign?.config.workflowName || "Publish"} currentPage={1}  workflowName=""/>

          <div className='spa-workspace'>
            <EditCampaignTabs stage={currentStage} />
          </div>
          <div className="spa-informationtext">
            <InformationText text={informationConfig.campaignPostProcess} type="Heading" />
          </div>
        </div>

        <div className='workflows-wrapper spa-wrapper'></div>

        <div className="workflow-body-container">
          {!userSalesRoles.isSpaUser && <EditCampaignSideBar />}
          <div className={`workflow-body-wrapper ${!userSalesRoles.isSpaUser && isSideNavOpen ? '' : 'collapsed'}`} style={{ paddingLeft: isSideNavOpen ? "20px" : "40px" }}>
            {userSalesRoles.isSpaUser &&
              <Card className="interactive-card" style={{ maxWidth: '800px' }}>
                {(campaignById.summary['CampaignType'] || "").startsWith('SMC') ?
                    <Text>Please click on <b>Save</b> to complete</Text>
                  :
                  <>
                    <Text>Proceed to certify BoM completion before publishing SPA. (Link to: <Button appearance="link" 
                      onClick={() => window.open(MsxLinks[selectedEnv], '_blank')!.focus()}>
                      MSX Configuration</Button>)
                    </Text><br />
                    <CheckboxGroup style={{padding: '10px'}}>
                      {renderCheckBoxes([
                        'MSX Content uploaded for SPA, tagged and validated',
                        'Sequence created and signed off',
                        'Segment created and signed off',
                        'Assignment rules configured and validated'
                      ])}
                    </CheckboxGroup>
                    <br />
                  </>
                }
              </Card>
            }
            <div style={{ visibility: userSalesRoles.isSpaUser ? 'hidden' : 'visible' }}>
              <CampaignPublish />
            </div>
          </div>
        </div>
        <EditCampaignFooter certCheck={certCheck} />

      </>
    )
  }

}
export const CampaignIngestFinalView = withErrorBoundary("Post Process - Campaign", CampaignIngestFinalViewC);