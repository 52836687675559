import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const CAMPAIGN_CACHE = 'campaignCache';
const DATASET_CACHE = 'datasetCache';
const REASSIGN_CACHE = 'datasetCache';
const SPA_CACHE = 'spaCache';
const TAB_CACHE = 'tabCache';

export enum UserMode {
    CREATE = 0,
    EDIT = 1,
    REVIEW = 2
}
export interface CampaignCache {
    campaignId?: string,
    mode: UserMode
}

export interface DatasetCache {
    datasetId?: string,
    mode: UserMode
}

export interface ReassignmentCache {
    reassignId?: string
}

interface CacheHandlerState {
    campaignCache: CampaignCache | undefined
    datasetCache: DatasetCache | undefined
    reassignmentCache: ReassignmentCache | undefined    
    tabCache: string
    // TODO: cache merge and spa campaign on tab switch so even after refresh it will land to the same tab.
    // TODO: always load query builder filters from cache, if there is selection from edit query update the cache with new one
}

const initialState: CacheHandlerState = {
    campaignCache: {
        campaignId: undefined,
        mode: UserMode.CREATE
    },
    datasetCache: {
        datasetId: undefined,
        mode: UserMode.CREATE
    },
    reassignmentCache: {
        reassignId: undefined
    },
    tabCache: "spaTab"
}

const saveBrowserCache = (key: string, value: any) => {
    const cache = JSON.parse(localStorage.getItem(SPA_CACHE) || '{}');
    cache[key] = value;
    localStorage.setItem(SPA_CACHE, JSON.stringify(cache));
}

export const CacheHandlerSlice = createSlice({
    name: 'cacheHandler',
    initialState,
    reducers: {
        setCampaignCache: (state, action: PayloadAction<CampaignCache>) => {
            if (!state.campaignCache) {
                state.campaignCache = initialState.campaignCache as CampaignCache;
            }
            state.campaignCache.campaignId = action.payload.campaignId;
            state.campaignCache.mode = action.payload.mode;
            saveBrowserCache(CAMPAIGN_CACHE, state.campaignCache);
        },
        setDatasetCache: (state, action: PayloadAction<DatasetCache>) => {
            if (!state.datasetCache) {
                state.datasetCache = initialState.datasetCache as DatasetCache;
            }
            state.datasetCache.datasetId = action.payload.datasetId;
            state.datasetCache.mode = action.payload.mode;
            saveBrowserCache(DATASET_CACHE, state.datasetCache);
        },
        setReassignmentCache: (state, action: PayloadAction<ReassignmentCache>) => {
            if (!state.reassignmentCache) {
                state.reassignmentCache = initialState.reassignmentCache as ReassignmentCache;
            }
            state.reassignmentCache.reassignId = action.payload.reassignId;
            saveBrowserCache(REASSIGN_CACHE, state.reassignmentCache);
        },
        setTabCache: (state, action: PayloadAction<string>) => {
            state.tabCache = action.payload;
            saveBrowserCache(TAB_CACHE, state.tabCache );
        },
        fetchCache: (state) => {
            const payload = localStorage.getItem(SPA_CACHE);
            const cache = JSON.parse(payload || '{}');
            state.campaignCache = cache[CAMPAIGN_CACHE] ? cache[CAMPAIGN_CACHE] as CampaignCache : initialState.campaignCache;
            state.tabCache = cache[TAB_CACHE] ? cache[TAB_CACHE] as string : "spaTab";
            if (payload == null) {
                cache[CAMPAIGN_CACHE] = state.campaignCache;
                cache[DATASET_CACHE] = state.datasetCache;
                cache[REASSIGN_CACHE] = state.reassignmentCache;
                cache[TAB_CACHE] = state.tabCache;
                localStorage.setItem(SPA_CACHE, JSON.stringify(cache));
            }
        }
    },
    extraReducers() {}
});

export const {
    setCampaignCache,
    setDatasetCache,
    setReassignmentCache,
    setTabCache,
    fetchCache
} = CacheHandlerSlice.actions;

export const CacheHandlerReducer = CacheHandlerSlice.reducer