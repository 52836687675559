/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from 'react-router-dom';
import { useEffect, useMemo, useCallback } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../root-redux/RootState";
import { getCampaignConfigDetails } from "../../../services/SPAAdmin/CampaignConfig.api";
import { getTaxonomy } from "../../../services/Taxonomy/Taxonomy.api";
import { fetchCache } from "../redux/CacheHandler.redux";
import { useSelector } from "react-redux";
import { RequestStatus } from "../../../model/ServiceRequestStatus.model";
import { getWorkflows } from "../../../services/GetWorkflows.api";
import { Helper } from "./Help";
import { filterReviewList } from "../redux/CampaignById.redux";
import { NavMenuItemType } from '../../../model/NavMenu.model';
import { useLocation } from 'react-router-dom';
import { getAppPersonas } from '../../../services/GetAppPersonaMapping.api';

export const Initialize: React.FC = () => {
    
    const navigate = useNavigate();
    const location = useLocation();
    const dispatchApp = useDispatch<AppDispatch>();

    const campaignConfig = useSelector((state: RootState) => state.campaignConfig);
    const taxonomy = useSelector((state: RootState) => state.taxonomy);
    const workflowsState = useSelector((state: RootState) => state.workflows);
    const selectedEnvItem = useSelector((state: RootState) => state.env.selectedEnvItem);
    const userSalesRoles = useSelector((state: RootState) => state.userSalesRoles);
    const appPersonas = useSelector((state: RootState) => state.appPersonas);

    const notAvailableStatus = useMemo(() => [RequestStatus.idle], []);

    const _onNavMenuItemClick = useCallback((navMenuItemType: NavMenuItemType, path: string | undefined = undefined) => {
        navigate("/" + NavMenuItemType[navMenuItemType] + (path ? `/${path}` : ''));
    }, [navigate]);

    // When env gets switched - Add the partial path in the below array to redirect from current page to Configuration page
    const checkAndRedirect = () => {
        if (!location.pathname.includes("redirect") && ["Configuration", "Campaign", "Dataset"].some(path => location.pathname.includes(path))) {
            _onNavMenuItemClick(NavMenuItemType.Configuration);
        }
    }
    
    // Do not add any dependencies, As it needs to invoke these API's once on page load or Env change
    useEffect(() => {
        dispatchApp(fetchCache());
        const oldEnv = window.localStorage.getItem("previousEnv");
        
        if ((selectedEnvItem === 'dev' && (userSalesRoles.isApprover || userSalesRoles.isSpaUser)) || (userSalesRoles.requestStatus !== RequestStatus.succeeded)) {
            return;
        }
        if ((notAvailableStatus.includes(campaignConfig.requestStatus) && !Helper.isEmpty(appPersonas.list)) || selectedEnvItem !== oldEnv) {
            dispatchApp(getCampaignConfigDetails());
        }
        if (notAvailableStatus.includes(taxonomy.requestStatus) || selectedEnvItem !== oldEnv) {
            dispatchApp(getTaxonomy());
        }
        if (notAvailableStatus.includes(workflowsState.status) || selectedEnvItem !== oldEnv) {
            dispatchApp(getWorkflows({ fetchUserObjects: true }))
        }
        if (selectedEnvItem !== oldEnv) {
            dispatchApp(getAppPersonas(null));
            window.localStorage.setItem("previousEnv", selectedEnvItem);
            checkAndRedirect();
        }
    }, [selectedEnvItem, userSalesRoles.requestStatus]);

    useEffect(() => {
        if (userSalesRoles.isApprover) {
            dispatchApp(filterReviewList());
        }
    }, [userSalesRoles.isApprover])

    return <></>;
};