import { createAsyncThunk } from "@reduxjs/toolkit"
import { RootState } from "../root-redux/RootState";
import { getRequest } from "./utils/GetRequest"
import { DownloadCsvFile } from "./utils/ExcelHandler";

export const getReassignmentUploadTemplate = createAsyncThunk<any, any, { state: RootState}>('reassignment/downloadTemplate', async (_, {getState}) => {
    try{
      const selectedEnvItem  = getState().env.selectedEnvItem;
      // const response = (await getRequest<string>(`${selectedEnvItem}/fileupload/downloadTemplate`));
      const response = (await getRequest<string>(`${selectedEnvItem}/fileupload/downloadReassignmentTemplate`));

      if(response.status == 200)
      {
        DownloadCsvFile(response);
      }
    }catch(e){
        console.log(e);
        return e;
    }
});