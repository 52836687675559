import { createAsyncThunk } from "@reduxjs/toolkit"
import { getRequest } from "../utils/GetRequest"
import { RootState } from "../../root-redux/RootState"

export const getTaxonomy = createAsyncThunk<any, void, { state: RootState }>('taxonomy/getTaxonomy', async (_, {getState, rejectWithValue}) => {
    const response = await getRequest<any>(`prod/taxonomy`);
    const data = response.data;
    if ((data["Code"] || "").startsWith('ERR')) {
        return rejectWithValue({ message: "Unable to fetch Taxonomy information: " + JSON.stringify(data) });
    }
    return response.data;
})