import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Divider, Toggle } from "../../common/HarmonyEnablers";
import "./UserProfileCard.css"
import { RootState } from "../../root-redux/RootState";
import { SuperAdminToggle } from "./SuperAdminToggle";
import { setEnvState } from "./user-profile-redux/UserProfile.actions.redux";
import { setIsSpaAreaPickerOpen } from "../../feature-components/spa-admin/redux/UserSalesRoles.redux";
import { SPA_ADMIN_MAIL } from "../../feature-components/spa-admin/SPAConstants";
import { useNavigate } from "react-router-dom";
import { NavMenuItemType } from "../../model/NavMenu.model";
import { resetPageNumber } from "../../feature-components/spa-admin/redux/CampaignDataCart.redux";

export const UserProfileCard:React.FC<any> = (props: any) => {
  const stateProps = useSelector((state: RootState) => ({
    userDetails: state.userDetails,
    superadminemail: state.superAdmin.superAdminEmail,
    userGroups: state.userGroups.list,
    currentEnv: state.env.selectedEnvItem,
    userSalesRoles: state.userSalesRoles
  }));

  const superAdminEmail = stateProps.superadminemail;
  const superAdminTrue = stateProps.userGroups.includes(superAdminEmail)? true : false
  const spaAdminTrue = stateProps.userGroups.includes(SPA_ADMIN_MAIL) || stateProps.userSalesRoles.isSpaAdmin;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const handleEnvironmentChange = (e: any) => {
    const selectedEnvironmentVal = e.value
    dispatch(setEnvState(selectedEnvironmentVal))
  };


  const userName = stateProps.userDetails.name
  const userEmail = stateProps.userDetails.username

  //getting the first and last word's initials out of the logged in user's name to show on anv bar
  const userInitials = (userName?userName:'').split(" ").map((n,i,a)=> i === 0 || i+1 === a.length ? n[0] : null).join("");

  return (
    <Card
      className="cardcomponent"
      style={{
        boxShadow: "1px 2px 6px grey",
        height: "100%",
        overflow: "auto",
        margin: "0px"
      }}
    >
      <div className="cardTop">
        <span>Microsoft</span>
      </div>
      <div className="cardMid">
        <div className="cardMidLeft">
          <div
            style={{
              fontWeight: "600",
              fontSize: "18px",
              backgroundColor: "#575757",
              color: "white",
              border: "2px solid #575757",
              borderRadius: "50%",
              width: "36px",
              height: "36px",
              lineHeight: "32px",
            }}
          >
            {userInitials}
          </div>
        </div>
        <div className="cardMidRight">
          <h6 id = "nebulausername">{userName}</h6>
          <span id = "nebulauseremail">{userEmail}</span>
        </div>
      </div>

      <div className="cardBottom">
        <div>
          <span>Switch to:</span>
        </div>
        { stateProps.currentEnv != "dev" && (!stateProps.userSalesRoles.isApprover && !stateProps.userSalesRoles.isSpaUser) && (
          <div>
            <span
              id = "developmentbutton"
              className="cardButton"
              onClick={() => {
                handleEnvironmentChange({ value: "dev" });
              }}
            >
              DEV
            </span>
          </div>
        )}
        {stateProps.currentEnv != "uat" && (
          <div>
            <span
              id = "stagingbutton"
              className="cardButton"
              onClick={() => {
                handleEnvironmentChange({ value: "uat" });
              }}
            >
              UAT
            </span>
          </div>
        )}
        {stateProps.currentEnv != "prod" && (
          <div>
            <span
              id = "productionbutton"
              className="cardButton"
              onClick={() => handleEnvironmentChange({ value: "prod" })}
            >
              PROD
            </span>
          </div>
        )}
      </div>

      {superAdminTrue && (
        <div style={{paddingTop:"6px"}}>
          <SuperAdminToggle />
        </div>
      )}

      {
        superAdminTrue && spaAdminTrue &&
        <Divider style={{margin: "5px 0"}}/>
      }

      {
        <div>
          { spaAdminTrue &&
            <>
              <span style={{padding:"0 4px", fontSize: '14px', marginRight: '5px'}}>SPA Admin </span>
              <Toggle id="spaareareviewertoggle" checked={stateProps.userSalesRoles.isApprover} onHeChange={(event: any)=>{
                if (event.target.checked === true) {
                  dispatch(setIsSpaAreaPickerOpen(true));
                  navigate("/" + NavMenuItemType[NavMenuItemType.Configuration]);
                  dispatch(resetPageNumber());
                } else {
                  window.location.href = window.location.href + '';
                }
              }}>
                <span id="checkedsuperadmin" slot="checked-message"></span>
                <span id="uncheckedsuperadmin" slot="unchecked-message">Area-Reviewer</span>
              </Toggle>
            </>
          }
          { stateProps.userSalesRoles.isApprover && 
            <>
              { !stateProps.userGroups.includes(SPA_ADMIN_MAIL) &&
                <span style={{padding:"0 14px", marginTop: '20px', fontSize: '14px', marginRight: '5px'}}>SPA Reviewer Area: </span>
              }
              <span><Button appearance="link" onClick={() => dispatch(setIsSpaAreaPickerOpen(true))}>({stateProps.userSalesRoles.userDetails.area})</Button></span> 
            </>
          }
        </div>
      }
    </Card>
  );
};