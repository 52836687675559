
import { createAsyncThunk } from "@reduxjs/toolkit"
import { RootState } from "../../../root-redux/RootState";
import { getRequest } from "../../../services/utils/GetRequest";
import { ConfigurationType } from "../../spa-admin/SPAConstants";
import { postRequestReleaseManagement } from "../../../services/utils/PostRequestReleaseManagement";
import { Helper } from "../../spa-admin/utils/Help";
import { Attribute, Campaign } from "../../../model/SPAModels/campaigns/campaign.Model";
import { AxiosResponse } from "axios";
import { Logger } from "../../../utils/Logger";


export const getMultipleConfigurationsByIdandVersion = createAsyncThunk<any, {id: string, versions: string[],type:string}>('campaigns/getMultipleCampaignsByIdandVersion', async (configurationDetails:any,{getState}) => {
    const versions = configurationDetails.versions;
    var result: any[] = [];
    if(configurationDetails.type === ConfigurationType.CAMPAIGN){
        const responses = await Promise.allSettled(versions.map((version: string) => getRequest<Campaign>(`dev/campaigns/${version}/${configurationDetails.id}`)))
        responses?.filter(res => res.status === 'fulfilled').forEach((res: PromiseSettledResult<AxiosResponse<Campaign>>) => {
            const successResponse = res as PromiseFulfilledResult<AxiosResponse<Campaign>>;
            if (successResponse.value && successResponse.value.data && successResponse.value.data.id) {
                // result.push(transformConfigurationForCampaignDisplay(res.data));
                result.push(successResponse.value.data);
            }
        })
    }
    return result
});
const transformConfigurationForCampaignDisplay = (campaign: Campaign) => {

    const displayCampaignSummaryProps:string[] = ["RecommendationTitle","RecommendationText"];
   
        var displayCampaignVersion:any = {};
        displayCampaignVersion["id"] = campaign.id;
        var displayCampaignVersioncells:any = {};

        displayCampaignVersioncells["Version"] = campaign.version;
        campaign.summary.map((summary) => {
            var index = displayCampaignSummaryProps.findIndex((prop) => prop === summary.key);
            if(index > -1){
                displayCampaignVersioncells[summary.key] = summary.value;
            }
        });
        displayCampaignVersioncells["TotalUploaded"] = campaign.inCart.length;
        displayCampaignVersioncells["Removed"] = campaign.outOfCart?.length??0;
        displayCampaignVersioncells["ExpiryDate"] = campaign.expiryDate;
        displayCampaignVersioncells["ActionByDate"] = campaign.actionByDate;
        displayCampaignVersion["cells"] = displayCampaignVersioncells;
       return displayCampaignVersion;

  }
  export const getConfigurationSummaryByIdandVersion = createAsyncThunk<any,{id:string, version:string,type:string}, { state: RootState }>('configurationSummaryByIdAndVersion_dev', async (configurationDetails:any,{getState}) => {
    var response: any = null;
    const selectedEnv = getState().env.selectedEnvItem ;
    if(configurationDetails.type === ConfigurationType.CAMPAIGN){

        response = await getRequest<string>(`${selectedEnv}/campaigns/${configurationDetails.version}/${configurationDetails.id}`);
    }
    return response?.data;
})
export const getConfigurationByIdandVersion_dev = createAsyncThunk<any,{id:string, version:string,type:string}, { state: RootState }>('configurationByIdAndVersion_dev', async (configurationDetails:any,{getState}) => {
    var response: any = null;
    if(configurationDetails.type === ConfigurationType.CAMPAIGN){

        response = await getRequest<string>(`dev/campaigns/${configurationDetails.version}/${configurationDetails.id}`);
    }
    return response?.data;
})
export const getConfigurationByIdandVersion_uat = createAsyncThunk<any,{id:string, version:string,type:string}, { state: RootState }>('configurationByIdAndVersion_uat', async (configurationDetails:any,{getState}) => {
    var response: any = null;
    if(configurationDetails.type === ConfigurationType.CAMPAIGN){

     response = await getRequest<string>(`uat/campaigns/${configurationDetails.version}/${configurationDetails.id}`);
    
    }
     return response?.data;
})
export const getConfigurationByIdandVersion_prod = createAsyncThunk<any,{id:string, version:string,type:string}, { state: RootState }>('configurationByIdAndVersion_prod', async (configurationDetails:any,{getState}) => {
    var response: any = null;
    if(configurationDetails.type === ConfigurationType.CAMPAIGN){

     response = await getRequest<string>(`prod/campaigns/${configurationDetails.version}/${configurationDetails.id}`);
    }
    return response?.data;
})

export const publishConfigurationReleaseManagement = createAsyncThunk<boolean, {id:string, env:string,type:string}, { state: RootState }>('configuration/publish', async (configurationDetails:any,{ getState }) => {
    var response: any = null;
    if(configurationDetails.type === ConfigurationType.CAMPAIGN){
         response = await postRequestReleaseManagement<boolean, null>(`${configurationDetails.env}/campaigns/${configurationDetails.id}/publish`, null);
         const data: any = response.data;
         if ((data["Code"] || "").startsWith('ERR')) {
             Logger.Instance.trackException(data["Code"], {
                 env: configurationDetails.env,
                 "API": `${configurationDetails.env}/campaigns/notification/SPA`,            
                 "Success": "false",
                 "Area" : getState().userSalesRoles.userDetails?.area!,
                 "Method" : "publishConfigurationReleaseManagement"
             });
     
         } else {
             Logger.Instance.trackEvent(data["Code"], {
                env: configurationDetails.env,
                "API": `${configurationDetails.env}/campaigns/notification/SPA`,            
                "Success": "true",
                "Area" : getState().userSalesRoles.userDetails?.area!,
                "Method" : "publishConfigurationReleaseManagement"
             });
     
         }
      //  response= await publishCampaignWorkflowReleaseManagement({ workflowId: configurationDetails.id, env: configurationDetails.env })
    }
  
    return response?.data;
  })

  export const postConfigurationDetailsForRelease = createAsyncThunk<any,any, { state: RootState }>('campaignById/postCampaignDetailsForRelease', async (configuration:any, {getState}) => {

    const selectedEnv = getState().env.selectedEnvItem ;
    var environment = configuration.env === "" || configuration.env === null ? selectedEnv : configuration.env;
    const summary = (configuration.summary || []).filter((s: Attribute) => s.key !== "CreatedOn").concat([{ key: "CreatedOn", value: Helper.getCurrentDatetime() }]);
    const modifiedConfiguration = {
        ...configuration,
        modifiedDate: Helper.getCurrentDatetime(),
        summary: summary
    };

    modifiedConfiguration.modifiedBy = getState().userDetails.localAccountId;
    var response: any = null;
    if(configuration.type === ConfigurationType.CAMPAIGN){
     response = await postRequestReleaseManagement<Campaign, Campaign>(`${environment}/campaigns`, modifiedConfiguration)
     const data: any = response.data;
        if ((data["Code"] || "").startsWith('ERR')) {
            Logger.Instance.trackException(data["Code"], {
                env: environment,
                "API": "/campaigns",                
                "AccountId": getState().userDetails.localAccountId,
                "Configuration": configuration,
                "Success": "false",           
                "Area" : getState().userSalesRoles.userDetails?.area!,
                "Method" : "postConfigurationDetailsForRelease"
            });
        } else {
            Logger.Instance.trackEvent(data["Code"], {
                env: environment,
                "API": "/campaigns",                
                "AccountId": getState().userDetails.localAccountId,
                "Configuration": configuration,
                "Success": "true",           
                "Area" : getState().userSalesRoles.userDetails?.area!,
                "Method" : "postConfigurationDetailsForRelease"
            });
        }
    }
    return response?.data;
})