import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../root-redux/RootState";
import { CartFilterSelector } from "./redux/CampaignCartList.Selector.redux";
import { Dialog, Select, Option, Button, ProgressRing } from "./SpaAdminScope";
import { setImpersonateArea, setIsApproverStatus, setIsSpaAdmin, setIsSpaAreaPickerOpen } from "./redux/UserSalesRoles.redux";
import './spa-styles.css';
import { Helper } from "./utils/Help";
import { useEffect, useState } from "react";
import { NavMenuItemType } from "../../model/NavMenu.model";
import { useNavigate } from "react-router-dom";
import { resetPageNumber } from "./redux/CampaignDataCart.redux";

export const SpaImpersonation: React.FC = () => {

    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const userSalesRoles = useSelector((state: RootState) => state.userSalesRoles);
    const taxonomyState = useSelector((state: RootState) => state.taxonomy);
    const taxonomy = useSelector(CartFilterSelector);
    const [areaList, setAreaList] = useState<string[]>([]);

    useEffect(() => {
        setAreaList(Array.from(taxonomy.details['Area'] || []).filter(a => a != null).sort((a, b) => a.localeCompare(b)))
    }, [taxonomy])

    return (
        <>

        <Dialog heading="Area-Reviewer Impersonation:" open={userSalesRoles.isSpaAreaPickerOpen} className='area-picker' onHeAfterHide={() => dispatch(setIsSpaAreaPickerOpen(false))} onHeAfterShow={() => {
            dispatch(setImpersonateArea(userSalesRoles.userDetails.area || areaList.at(0)));
            dispatch(setIsApproverStatus(true));
            dispatch(setIsSpaAdmin(false));
        }}>
            {Helper.notReadyStatus(taxonomyState.requestStatus, userSalesRoles.requestStatus) ?
                <div className='spa-progressring'>
                    <ProgressRing label="Loading..." indeterminate></ProgressRing>
                </div>
                :
                <Select onHeChange={(event: any) => {
                    dispatch(setImpersonateArea(event.target.value));
                    dispatch(setIsApproverStatus(true));
                    dispatch(resetPageNumber());
                    dispatch(setIsSpaAdmin(false));
                    navigate("/" + NavMenuItemType[NavMenuItemType.Configuration]);
                }}>
                    {
                        areaList.map(area => <Option>{area}</Option>)
                    }
                </Select>
            }

            <Button slot="footer" appearance="primary" onClick={() => dispatch(setIsSpaAreaPickerOpen(false))}>Ok</Button>
        </Dialog>
        </>
    );
}