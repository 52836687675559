import { useDispatch } from "react-redux";
import { Select, Option, Button, Tooltip } from "../../../common/HarmonyEnablers";
import { AdlsConnection } from "../../../model/connections/AdlsConnection.model";
import { Icon, TextField } from "../ConnectionScope";
import "../connections.css"
import { AppDispatch, RootState } from "../../../root-redux/RootState";
import { postConnections } from "../../../services/PostConnections.api";
import { ConnectionType } from "../../../model/connections/ConnectionType.model";
import { useEffect, useState } from "react";
import { TeamsList } from "../../../model/role-based-access-control/RoleBasedAcessControl.model";
import { AppPersona } from "../../../model/app-persona/AppPersona.model";
import { withErrorBoundary } from "../../../shared-components/ErrorBoundary";
import { useSelector } from "react-redux";
import { Connection } from "../../../model/connections/Connection.model";


const AdlsConnectionPanelC: React.FC<{connectionData: AdlsConnection, editMode: boolean, appScopes: string[] | undefined, connectionType: ConnectionType, subscriptions: {key: string, name: string}[], authType:string}> = ({connectionData, editMode, appScopes, connectionType, subscriptions, authType}) => {
    const dispatch = useDispatch<AppDispatch>()
    const [data, setData] = useState<AdlsConnection>(connectionData);
    const selectedEnv =  useSelector((state: RootState) => state.env.selectedEnvItem);

    const adbScopeDev = "https://adb-5404863101054673.13.azuredatabricks.net/?o=5404863101054673#secrets/createScope";
    const adbScopUat = "https://adb-2987319840690271.11.azuredatabricks.net/?o=2987319840690271#secrets/createScope";
    const adbScopProd = "https://adb-5011763184037517.17.azuredatabricks.net/?o=5011763184037517#secrets/createScope";

    useEffect(() => {
        setData(connectionData)
    }, [connectionData])
    
    if (!connectionData && !editMode) {
        return <></>
    }

    const canEnableSave = () => {
        if(editMode)
            {
                if( authType == "spn" && (!data.connectionName?.trim() || !data.appScope || !data.appClientID?.trim() || !data.appClientSecret?.trim() || !data.adlsStorageAccount?.trim() || !data.adlsContainer?.trim() || !data.fileFormat?.trim()))
                    return false;
                if( authType == "msi" && (!data.connectionName?.trim() || !data.adlsStorageAccount?.trim() || !data.adlsContainer?.trim() || !data.fileFormat?.trim()))
                    return false;
                if(connectionData && JSON.stringify(connectionData) === JSON.stringify(data))
                    return false;
                return true;
            }
        return false;
    }

    const onSaveClick = () => {
        
        var finalData = {} as AdlsConnection extends Connection ? AdlsConnection : Connection;
        if(authType == "spn")
            finalData = {...data, useMSI: 0, msiClientId: ''};
        else
        finalData = {...data, useMSI: 1, appClientID: '', appClientSecret: '', msiClientId : data.msiClientId || ''};

        dispatch(postConnections({ newConnection: finalData, type: connectionType }))
    }

    let subscriptionName = ''
    if (!editMode) {
        subscriptionName = subscriptions.filter(item => item.key === data.subscriptionKey)[0]?.name || ''
    }

    return ( 
        <>       
                <div className='input-row'>
                    <div>Name</div>
                    
                        <TextField id="adlsnamefield"  aria-label="Name" required disabled={!editMode} placeholder="Name" value={data.connectionName || ''} 
                        onHeInput={(e: any) => {
                            setData({...data, connectionName: e.target.value})
                            
                        }}/>
                </div>
                <div className='input-row'>
                    <div>Tenant</div>
                    <Select
                            value= {data.subscriptionKey || ''}
                            slot="action"
                            fixed-placement
                            disabled={!editMode}
                            placeholder={subscriptionName || 'Select'}
                            id = "adlstenantfield"
                            >
                            {
                                subscriptions?.map((item) => (
                                    <Option
                                        key={item.key}
                                        role="menuitemcheckbox"
                                        value={item.key}
                                        onHeSelected={(e: any) => {
                                            setData({...data, subscriptionKey: e.target.value})
                                            
                                        }}
                                    >
                                        {item.name}
                                    </Option>
                                ))
                            }
                        </Select>
                    </div>
                <div className='input-row'>
                    <div>App Scope
                    <Icon id="info44" name="info" style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }} />
                            <Tooltip anchor="info44" fixedPlacement={true} placement='bottom'>
                                Select the app scope. If creating, then create the app scope in all the three environments.
                            </Tooltip>
                    </div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Select
                                value= { authType == "msi" ? data.appScope || 'nebula' : data.appScope || '' }
                                slot="action"
                                fixed-placement
                                disabled={!editMode}
                                placeholder='Select'
                                style={{width: '500px'}}
                                id = "adlsappscopefield"
                                >
                                {
                                    appScopes?.map(item => (
                                        <Option
                                            key={item}
                                            role="menuitemcheckbox"
                                            onHeSelected={(e: any) => {
                                                setData({...data, appScope: e.target.textContent})
                                                
                                            }}
                                        >
                                            {item}
                                        </Option>
                                    ))
                                }
                            </Select>
                            {/* TODO add this to aka.ms */}
                            {
                                selectedEnv == "dev" ? <a id="adlsdevscope" style={{marginLeft: '10px'}} target="_blank" href={adbScopeDev}>Add New Scope</a> 
                                : selectedEnv == "uat" ? <a id="adlsuatscope" style={{marginLeft: '10px'}} target="_blank" href={adbScopUat}>Add New Scope</a> 
                                : selectedEnv == "prod" ? <a id="adlsprodscope" style={{marginLeft: '10px'}} target="_blank" href={adbScopProd}>Add New Scope</a> 
                                : <a id="adlsdevscope" style={{marginLeft: '10px'}} target="_blank" href={adbScopeDev}>Add New Scope</a>
                            }
                             <Icon id="infoaddscopeadls" name="info" style={{ marginLeft: "4px", cursor: "pointer", color: "#0078D4" }} tabIndex={0}/>
                            <Tooltip anchor="infoaddscopeadls" fixedPlacement={true} placement='bottom'>
                            The new scope needs to be created in all the three environments
                            </Tooltip>
                        </div>
                    </div>
                {authType == "spn" &&
                <div  className='input-row'>
                    <div>App ClientId
                    <Icon id="info44" name="info" style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }} />
                            <Tooltip anchor="info44" fixedPlacement={true} placement='bottom'>
                            Select app Client id
                            </Tooltip>
                    </div>
                    <TextField id = "adlsappclientidfield"  aria-label="App ClientId" required disabled={!editMode} placeholder="ClientId" value={data.appClientID || ''} onHeInput={(e: any) => {
                            setData({...data, appClientID: e.target.value})
                            
                        }}/>
                 
                </div>
            }
            {authType == "msi" &&
                <div  className='input-row'>
                    <div>MSI ClientId 
                    <Icon id="info441" name="info" style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }} tabIndex={0}/>
                            <Tooltip anchor="info441" fixedPlacement={true} placement='bottom'>
                            Enter the MSI Client Id:
                            Provide the name of the Key Vault secret that contains the MSI Client ID you wish to use for authentication.
                            If you prefer to use the default Nebula MSI Client ID, leave this field empty.
                            </Tooltip>
                    </div>
                    <TextField id = "adlsmsoclientidfield"  aria-label="MSI ClientId" disabled={!editMode} placeholder="ClientId" value={data.msiClientId || ''} onHeInput={(e: any) => {
                            setData({...data, msiClientId: e.target.value})
                            
                        }}/>
                 
                </div>
            }
            {authType == "spn" &&
                <div className='input-row'>
                    <div>App Client Secret
                    <Icon id="info45" name="info" style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }} />
                            <Tooltip anchor="info45" fixedPlacement={true} placement='bottom'>
                           Select Key vault app secret key
                            </Tooltip>
                    </div>
                    <TextField id = "adlsappclientsecretfield"  aria-label="App Client Secret" required disabled={!editMode} placeholder="Client Secret" value={data.appClientSecret || ''} onHeInput={(e: any) => {
                        setData({...data, appClientSecret: e.target.value})
                            
                    }}/>
                    
                </div>
                }
                <div className='input-row'>
                    <div>Storage Account
                    <Icon id="info43" name="info" style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }} />
                            <Tooltip anchor="info43" fixedPlacement={true} placement='bottom'>
                            Select the azure storage account 
                            </Tooltip>
                    </div>
                    <TextField id = "adlsstorageaccountfield"  aria-label="Storage Account" required disabled={!editMode} placeholder="Storage Account" value={data.adlsStorageAccount || ''} onHeInput={(e: any) => {
                        setData({...data, adlsStorageAccount: e.target.value})
                            
                    }}/>
                    
                </div>
                <div className='input-row'>
                    <div>Container
                    <Icon id="info46" name="info" style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }} />
                            <Tooltip anchor="info46" fixedPlacement={true} placement='bottom'>
                            select the ADLS container
                            </Tooltip>
                    </div>
                    <TextField id = "adlscontainerfield"  required disabled={!editMode} aria-label="Container" placeholder="Container" value={data.adlsContainer || ''} 
                        onHeInput={(e: any) => {
                            setData({...data, adlsContainer: e.target.value})
                            
                        }}/>
                    
                </div>
                <div className='input-row'>
                    <div>File Format
                    <Icon id="info47" name="info" style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }} />
                            <Tooltip anchor="info47" fixedPlacement={true} placement='bottom'>
                            Select file format
                            </Tooltip>
                    </div>
                    <TextField id = "adlsfileformatfield"  required disabled={!editMode} placeholder="File Format" aria-label="File Format" value={data.fileFormat || ''} 
                        onHeInput={(e: any) => {
                            setData({...data, fileFormat: e.target.value})
                            
                        }}/>       
                </div>
            <div slot="footer" className="panel-footer">
                <Button id = "adlssavefield"  onClick={onSaveClick} disabled={!canEnableSave()} appearance={editMode ? 'primary': undefined}>
                    Save
                </Button>
            </div>
        </>     
    )
}
export const AdlsConnectionPanel = withErrorBoundary("Adls Connection Panel", AdlsConnectionPanelC);