import React from "react";
import { checkboxGroup, createScope, partnerCenterTheme, subway, subwayStop } from "@harmony/enablers/react";
import {
  dataGrid,
  button,
  icon,
  commandBar,
  dropdown,
  menu,
  menuItem,
  searchBox,
  pagination,
  badge,
  hoverCard,
  breadcrumb,
  breadcrumbItem,
  divider,
  filterPill,
  tabs, 
  tab, 
  tabPanel,
  persona,
  pageTitle,
  card,
  textField,
  tooltip,
  text,
  textArea,
  datePicker,
  select,
  option,
  radioGroup,
  radio, 
  checkbox,
  flyInPanel,
  toggle,
  progressRing,
  messageBar,
  accordion,
  accordionItem,
  dialog,
  inlineStatus
} from '@harmony/enablers/react';

const scope = createScope({
    basePath: "https://harmonyenablers.microsoft.com/npm/@harmony/enablers/6.15.0",
  reactInstance: React,
  styles: [partnerCenterTheme]
});

export const DataGrid = scope.forReact(dataGrid);
export const Button = scope.forReact(button);
export const Icon = scope.forReact(icon);
export const CommandBar = scope.forReact(commandBar);
export const Dropdown = scope.forReact(dropdown);
export const Menu = scope.forReact(menu);
export const MenuItem = scope.forReact(menuItem);
export const SearchBox = scope.forReact(searchBox);
export const Pagination = scope.forReact(pagination);
export const Badge = scope.forReact(badge);
export const HoverCard = scope.forReact(hoverCard);
export const Breadcrumb = scope.forReact(breadcrumb);
export const BreadcrumbItem = scope.forReact(breadcrumbItem);
export const Divider = scope.forReact(divider);
export const FilterPill = scope.forReact(filterPill);
export const Tab = scope.forReact(tab);
export const Tabs = scope.forReact(tabs);
export const TabPanel = scope.forReact(tabPanel);
export const Persona = scope.forReact(persona);
export const PageTitle = scope.forReact(pageTitle);
export const Card = scope.forReact(card);
export const Tooltip = scope.forReact(tooltip);
export const TextField = scope.forReact(textField);
export const Text = scope.forReact(text);
export const TextArea = scope.forReact(textArea);
export const DatePicker = scope.forReact(datePicker);
export const Select = scope.forReact(select);
export const Option = scope.forReact(option);
export const RadioGroup = scope.forReact(radioGroup);
export const Radio = scope.forReact(radio);
export const CheckBox = scope.forReact(checkbox);
export const CheckboxGroup = scope.forReact(checkboxGroup);
export const FlyInPanel = scope.forReact(flyInPanel);
export const Toggle = scope.forReact(toggle);
export const ProgressRing = scope.forReact(progressRing);
export const MessageBar = scope.forReact(messageBar);
export const Accordion = scope.forReact(accordion);
export const AccordionItem = scope.forReact(accordionItem);
export const Dialog = scope.forReact(dialog);
export const Checkbox = scope.forReact(checkbox);
export const InlineStatus = scope.forReact(inlineStatus);
export const Subway = scope.forReact(subway);
export const SubwayStop = scope.forReact(subwayStop);
