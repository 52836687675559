import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RequestStatus } from "../../../model/ServiceRequestStatus.model";
import { getBulkReassignmentList, postReassignmentRequest } from "../../../services/BulkReassignment.api";
import { BulkReassign } from "../../../model/SPAModels/BulkReassign.Model";

export interface ReassignmentListState {
    reassignRequestList: BulkReassign[],
    status: RequestStatus,
    currPage: number,
    totalPage: number,
    error: string | undefined
}

const initialState: ReassignmentListState = {
    reassignRequestList: [],
    status: RequestStatus.idle,
    currPage: 1,
    totalPage: 1,
    error: ''
}

export const ReassignmentListSlice = createSlice({
    name: 'bulkreassign',
    initialState,
    reducers: {
      changePageNumber: (state, action: PayloadAction<number>) => {
        state.currPage = action.payload
      }
    },
    extraReducers(builder) {
        builder
            .addCase(getBulkReassignmentList.pending, (state, action) => {
              state.status = RequestStatus.loading
            })
            .addCase(getBulkReassignmentList.fulfilled, (state, action: PayloadAction<BulkReassign[]>) => {
              state.status = RequestStatus.succeeded
              state.reassignRequestList = action.payload
              state.error = undefined
            })
            .addCase(getBulkReassignmentList.rejected, (state, action: any) => {
              state.status = RequestStatus.failed
              const message = (action?.payload && action.payload["Message"] ? action.payload["Message"] : action.error.message);
              state.error = "Reassignment List: " + message;
            })
            .addCase(postReassignmentRequest.pending, (state, _) => {
              state.status = RequestStatus.loading
              state.error = undefined
            })
            .addCase(postReassignmentRequest.fulfilled, (state, action: any) => {
                state.status = RequestStatus.succeeded
                state.error = undefined
            })
            .addCase(postReassignmentRequest.rejected, (state, action: any) => {
                state.status = RequestStatus.failed
                state.error = "Post Request: " + (action.payload['Message'] || action.error.message);
            })
    }
});

export const {
  changePageNumber
} = ReassignmentListSlice.actions;

export const reassignListReducer = ReassignmentListSlice.reducer