import { CommandBar, Button, Icon } from "../../workflows/WorkflowsScope";
import { AppDispatch, RootState } from "../../../root-redux/RootState";
import { useDispatch, useSelector } from "react-redux";
import { patchReleaseWorkflow, setReleaseWorkflowStatus } from "../ReleaseData.redux";
import { addRelease, setReleaseStatus } from "../Releases.redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { uatReleasePermissionsSelector } from "../ReleaseRBAC.selectors.redux";
import { ErrorNotification } from "../../notifications/ErrorNotification";
import { SuccessNotification } from "../../notifications/SuccessNotification";
import { withErrorBoundary } from "../../../shared-components/ErrorBoundary";
import { postReleaseReleaseManagement } from "../../../services/PostRelease.api";
import { Environment, Release } from "../../../model/releases/Release.model";
import { Schedule } from "../../../model/workflows/Schedule.model";
import { postWorkflowReleaseManagement } from "../../../services/PostWorkflow.api";
import { setWorkflowSummaryStatus } from "../../workflows/workflows-redux/Workflows.redux";
import { publishWorkflowReleaseManagement } from "../../../services/PublishWorkflow.api";
import { getWorkflowsBySubKeys } from "../../../services/GetWorkflows.api";
import { SPA_SubscriptionKey } from "../../spa-admin/SPAConstants";

export const handlePostRelease = (props: any) => {

    const environments: Environment[] = [
        { environment: "dev", isDeployed: false },
        { environment: "uat", isDeployed: true },
        { environment: "prod", isDeployed: false },
    ];

    const releaseData: Release = {
        environments: environments,
        timestamp: "",
        releaseId: props.releaseId,
        workflowId: props.workflowId,
        version: props.version || '1',
        id: uuidv4().toString(),
        type: "Propagate",
        status: "Published",
        scheduleCreated: false
    };

    return releaseData;
};

const NonPropagateDatasetReleaseActionsUATC: React.FC<any> = (props: any) => {
    const userId = useSelector((state: RootState) => state.userDetails.localAccountId);
    const userSalesRoles = useSelector((state: RootState) => state.userSalesRoles);
    const workflowId = props.workflowId;
    const releaseId = props.releaseId;
    const uatWorkflow = props.uatWorkflow;
    const subscriptionKey = props.subscriptionKey;
    const selectedReleaseIds = props.selectedReleaseIds;
    const dispatchApp = useDispatch<AppDispatch>();
    const dispatch = useDispatch();
    const actionedVersion = props.selectedVersion;

    const [disableUatDeploy, setDisableUatDeploy] = useState(true);
    const releaseRows = props.releaseRows;
    const [showErrorNotif, setShowErrorNotif] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [successText, setSuccessText] = useState("");
    const [showSuccessNotif, setShowSuccessNotif] = useState(false);
    const [uatApproveReject, setuatApproveReject] = useState(true);

    const releasePermUat = useSelector(uatReleasePermissionsSelector).release;
    const approvePermUat = useSelector(uatReleasePermissionsSelector).approve;
    const superAdmin = useSelector(
        (state: RootState) => state.isSuperAdmin.isSuperAdmin
    );

    useEffect(() => {
        const uatStatus = releaseRows.find((x:any) => x.cells.field1 == actionedVersion)?.cells.field4;
        const uatApprove = releaseRows.find((x:any) => x.id == actionedVersion)?.cells.field4;
        setDisableUatDeploy(actionedVersion ? (uatStatus == "Draft" ? false : true) : true);
        setuatApproveReject(uatApprove == "Awaiting Approval" ? false : true);
    }, [actionedVersion, releaseRows]);

    const handleUatDeployment = () => {

        if ((uatWorkflow.dataIngestion?.length == 0)) {
            setErrorText("Please onboard the data sources before deploying");
            setShowErrorNotif(true);
            setDisableUatDeploy(true);
        }
        else {

            const updatedWorkflow = {...uatWorkflow, status:"Submitted", environment: "uat" };
            dispatchApp(postWorkflowReleaseManagement( { workflow: updatedWorkflow, env: "uat" }));
            
            const propagateRelease = handlePostRelease({workflowId: workflowId , version : actionedVersion , releaseId : releaseId });
            const updatedPropagateRelease = {...propagateRelease, status:"Submitted", environment: "uat" }
            dispatchApp(postReleaseReleaseManagement({payload: updatedPropagateRelease, env:"uat"}));
            dispatch(addRelease({input: updatedPropagateRelease, env:"uat"}));
            dispatch(setReleaseStatus({version: actionedVersion, status: "Submitted", env: "uat"}));
            setDisableUatDeploy(true);
            setShowSuccessNotif(true);
            setSuccessText("Submitted for Approval");
        }
    };

    const handleAprove = async () => {

        const hasApprovedList = [];
        hasApprovedList.push(userId);
        const updatedWorkflow = {...uatWorkflow, hasApproved:hasApprovedList, environment: "uat", schedule: {} as Schedule };
        dispatch(patchReleaseWorkflow({input: updatedWorkflow, env: "uat"}));
  
        dispatch(setWorkflowSummaryStatus({workflowId: workflowId, status: "Published"}));
        dispatch(setReleaseWorkflowStatus({status: "Published", env:"uat"}));
  
        dispatch(setReleaseStatus({version: actionedVersion, status: "Published", env: "uat"}));
        const propagateRelease = handlePostRelease({workflowId: workflowId , version : actionedVersion , releaseId : releaseId });
        dispatchApp(postReleaseReleaseManagement({payload: propagateRelease, env:"uat"}));
        dispatchApp(postWorkflowReleaseManagement( { workflow: updatedWorkflow, env: "uat" }));

        await dispatchApp(publishWorkflowReleaseManagement({workflowId: workflowId , env:"uat"})).then(unwrapResult).then((result)=>{ 
            if(result == true)
            {
                dispatchApp(getWorkflowsBySubKeys({subKeys: SPA_SubscriptionKey}));
                setShowSuccessNotif(true); setSuccessText("UAT Deployment completed")
            }
            else
            {
                setErrorText("UAT deployment failed, Invalid workflow"); setShowErrorNotif(true);
                const updatedWorkflow = {...uatWorkflow, status:"New", environment: "uat" };
                dispatch(patchReleaseWorkflow({input: updatedWorkflow, env: "uat"}));
                dispatchApp(postWorkflowReleaseManagement( { workflow: updatedWorkflow, env: "uat" }));

                dispatch(setWorkflowSummaryStatus({workflowId: workflowId, status: "New"}));
                dispatch(setReleaseWorkflowStatus({status: "New", env:"uat"}));

                dispatch(setReleaseStatus({version: actionedVersion, status: "New", env: "uat"}));
            } 
          });
          setuatApproveReject(true);
    }

    const handleReject = async () => {
        const updatedWorkflow = {...uatWorkflow, status:"New", environment: "uat" };
        dispatch(patchReleaseWorkflow({input: updatedWorkflow, env: "uat"}));
        dispatchApp(postWorkflowReleaseManagement( { workflow: updatedWorkflow, env: "uat" }));

        dispatch(setWorkflowSummaryStatus({workflowId: workflowId, status: "New"}));
        dispatch(setReleaseWorkflowStatus({status: "New", env:"uat"}));
        dispatch(setReleaseStatus({version: actionedVersion, status: "New", env: "uat"}));
          
        setShowSuccessNotif(true); 
        setSuccessText("Workflow Rejected");
        setDisableUatDeploy(false);
        setuatApproveReject(true);
    }


    return (
        <div
            style={{
                display: "flex",
                padding: "4px",
                fontSize: "14px",
                alignItems: "center",
                marginRight: "12px",
                marginLeft: "12px",
            }}
        >
            <div>
                <span style={{ fontWeight: "700", marginRight: "8px" }}>UAT</span>
            </div>
            <div>
                <SuccessNotification
                    successNotif={showSuccessNotif}
                    setsuccesnotif={setShowSuccessNotif}
                    successText={successText}
                />{" "}
            </div>
            <div>
                {" "}
                <ErrorNotification
                    errorNotif={showErrorNotif}
                    seterrornotif={setShowErrorNotif}
                    errorText={errorText}
                />{" "}
            </div>

            <div>
                <CommandBar>
                    <Button
                        style={{ border: "none", outline: "none", boxShadow: "none" }}
                        disabled={disableUatDeploy || selectedReleaseIds.length !== 1}
                        appearance="command"
                        onClick={() => {
                            if (releasePermUat.includes(subscriptionKey) || superAdmin || userSalesRoles.isSpaUser || userSalesRoles.isSpaAdmin) {
                                handleUatDeployment();
                            } else {
                                setErrorText("You do not have permission to deploy workflow in uat");
                                setShowErrorNotif(true);
                            }
                        }}
                    >
                        <Icon slot="start" name="rocket"></Icon>
                        Deploy
                    </Button>

                    <Button appearance="command"
                        disabled={uatApproveReject || selectedReleaseIds.length !== 1}
                        onClick={async () => {
                            if (approvePermUat.includes(subscriptionKey) || superAdmin || userSalesRoles.isSpaUser || userSalesRoles.isSpaAdmin) {
                                await handleAprove();
                            } else {
                                setErrorText("You do not have permission to deploy workflow in uat");
                                setShowErrorNotif(true);
                            }
                        }}>
                        <Icon slot="start" name="accept"></Icon>
                        Approve
                    </Button>

                    <Button appearance="command"
                        disabled={uatApproveReject || selectedReleaseIds.length != 1}
                        onClick={async () => {
                            if (approvePermUat.includes(subscriptionKey) || superAdmin || userSalesRoles.isSpaUser || userSalesRoles.isSpaAdmin) {
                                await handleReject();
                            } else {
                                setErrorText("You do not have permission to deploy workflow in uat");
                                setShowErrorNotif(true);
                            }
                        }}>
                        <Icon slot="start" name="calculatormultiply"></Icon>
                        Reject
                    </Button>

                </CommandBar>
            </div>
        </div>
    );
};
export const NonPropagateDatasetReleaseActionsUAT = withErrorBoundary("NonPropagateDatasetReleaseActionsUAT", NonPropagateDatasetReleaseActionsUATC);
