import { useSelector } from "react-redux";
import { withErrorBoundary } from "../../../shared-components/ErrorBoundary";
import { Accordion, AccordionItem, Button, Card, Dialog, Divider, Icon, InlineStatus, MessageBar, Subway, SubwayStop, Tooltip } from "../../spa-admin/SpaAdminScope";
import { RootState } from "../../../root-redux/RootState";
import { useLocation } from "react-router";
import { subwayStatus, ValidationState } from "../../spa-admin/redux/ReleaseDashboard.redux";
import { useEffect, useState } from "react";

import './release-dashboard.css';
import { ReleaseConfDataGridRow } from "../../release-management/Configuration/ReleaseConfiguration";
import { Campaign } from "../../../model/SPAModels/campaigns/campaign.Model";
import { SPA_30MINS_IN_MILLISECONDS, SPA_12HRS_IN_MILLISECONDS } from "../SPAConstants";

interface ILiveDashboardProps {
    env: 'DEV'|'UAT'|'PROD'|'ALL';
    viewState: boolean;
    releaseRows: ReleaseConfDataGridRow[];
    setViewState: React.Dispatch<React.SetStateAction<boolean>>
    onDeploy?: () => void;
    buttonText?: string;
}

enum SectionView {
    segmentView = 1,
    recsView = 2
}

const hasWorkflowDelayed = (processingStatus: any|undefined, modifiedOn: Date) => {
    const now = new Date();
    const utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
    const diffFlag = (utc.getTime() - (modifiedOn || utc).getTime()) > SPA_30MINS_IN_MILLISECONDS;
    return processingStatus == undefined && diffFlag;
}

const hasNebulaDelayed = (modifiedOn: Date) => {
    const now = new Date();
    const utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
    return (utc.getTime() - (modifiedOn || utc).getTime()) > SPA_12HRS_IN_MILLISECONDS;
}

const getNextETA = (modifiedOn: Date) => {
    const now = new Date();
    const utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
    const diff = utc.getTime() - (modifiedOn || utc).getTime();
    const hours = Math.floor(diff / 3600000);
    const attempt = Math.floor(hours/4)+1;
    return `${attempt === 1 ? "1st" : attempt === 2 ? "2nd" : "3rd"} refresh is in ${4-(hours%4)} hour's`;
}

const RenderSubwayStatus = (env: 'DEV'|'UAT'|'PROD'|'ALL', subwayStatus: subwayStatus, setIsDeploy: any, modifiedOn: Date, inCartLength: number, postDeployInfo?: any[], releaseStatus?: string) => {

    const [sectionViewState, setSectionView] = useState<SectionView>(SectionView.segmentView);

    const ValidationStatus = (postDeployInfo || []).find((x: any) => x.type === 1);
    const ValidationWorkflowStatus = releaseStatus !== "Deployed" ? 0 :
                                     hasWorkflowDelayed(ValidationStatus, modifiedOn) ? 1 :
                                     ValidationStatus == undefined ? 2 :
                                     Object.keys(ValidationStatus).length > 0 && ValidationStatus.error == null ? 3 : 4;

    const PreProcessingStatus = (postDeployInfo || []).find((x: any) => x.type === 2);
    const PreProcessingWorkflowStatus = releaseStatus !== "Deployed" ? 0 :
                                        hasWorkflowDelayed(PreProcessingStatus, modifiedOn) ? 1 :
                                        PreProcessingStatus == undefined ? 2 :
                                        Object.keys(PreProcessingStatus).length > 0 && PreProcessingStatus.error == null ? 3 : 4;

    const isCampaignHasRecs = releaseStatus === "Deployed" && subwayStatus.recsStatus === 'complete' && ValidationWorkflowStatus === 3 && PreProcessingWorkflowStatus === 3;
    const allNebulaWorkflowValidation = subwayStatus.datasetValidationState === ValidationState.valid && subwayStatus.segmentValidationState === ValidationState.valid && 
                                        subwayStatus.sequenceValidationState === ValidationState.valid && ValidationWorkflowStatus === 3 && PreProcessingWorkflowStatus === 3;

    useEffect(() => {
        setIsDeploy(
            subwayStatus.datasetValidationState === ValidationState.valid &&
            subwayStatus.segmentValidationState === ValidationState.valid && 
            subwayStatus.sequenceValidationState === ValidationState.valid
        );
    }, [setIsDeploy, subwayStatus.datasetValidationState, subwayStatus.segmentValidationState, subwayStatus.sequenceValidationState]);

    const renderAssignmentRules = (assignmentRules: any, status?: ValidationState) => {
        if (ValidationState.loading === status) {
            return <InlineStatus type="in-progress">Loading...</InlineStatus>;
        } else if (ValidationState.failed === status) {
            return <InlineStatus type="error">Couldn't find any assignment rule</InlineStatus>;
        }
        return (
            Object.keys(assignmentRules || {}).map((ruleName) => {
                const count = assignmentRules[ruleName];
                return <InlineStatus type={count > 0 ? "complete" : "error"}>{ruleName} <b>: {count}</b></InlineStatus>;
            })
        );
    }

    return (
        <div style={{display: 'flex'}}>
            <Subway label="Publishing Status" orientation="vertical" style={{flexGrow: 1, maxWidth: '500px', minWidth: "450px"}}>
                {
                    env === 'ALL' &&
                    <SubwayStop status={releaseStatus === "Deployed" ? 'complete' : 'not_started'}>
                        {releaseStatus === "Deployed" ? "Deployed" : "Not Deployed yet"}
                    </SubwayStop>
                }
                <SubwayStop status={subwayStatus.datasetValidationState}>
                    Campaign Dataset
                    <div slot="details">
                        {subwayStatus.datasetValidationState === ValidationState.valid && "Dataset's are valid and Published"}
                        {subwayStatus.datasetValidationState === ValidationState.loading && "Loading..."}
                        {subwayStatus.datasetValidationState === ValidationState.invalid && (
                            subwayStatus.inValidDatasets.length > 0 ? "Invalid Dataset's: " + subwayStatus.inValidDatasets.join(", ") : "Dataset's not found"
                        )}
                        {subwayStatus.datasetValidationState === ValidationState.failed && "Unable to Validate Dataset's"}
                    </div>
                </SubwayStop>
                <SubwayStop status={subwayStatus.segmentValidationState}>
                    Campaign Segment's
                    <div slot="details">
                        {subwayStatus.segmentValidationState === ValidationState.loading && "Loading..."}
                        {subwayStatus.segmentValidationState === ValidationState.invalid && "Segments are not valid"}
                        {subwayStatus.segmentValidationState === ValidationState.failed && "Unable to Validate Segments"}
                        {subwayStatus.segmentValidationState === ValidationState.valid && "Found valid segment's "}
                        {subwayStatus.segments.length > 0 && sectionViewState !== SectionView.segmentView && <Button appearance="link" style={{marginTop: '-5px'}} onClick={() => setSectionView(SectionView.segmentView)}>See details</Button>}
                    </div>
                </SubwayStop>
                <SubwayStop status={subwayStatus.sequenceValidationState}>
                    Campaign Sequence's
                    <div slot="details">
                        {subwayStatus.segmentValidationState === ValidationState.loading && "Loading..."}
                        {subwayStatus.sequenceValidationState === ValidationState.invalid && "Sequence are not valid or not attached"}
                        {subwayStatus.sequenceValidationState === ValidationState.failed && "Unable to Validate Sequence's"}
                        {subwayStatus.sequenceValidationState === ValidationState.valid && "Found valid Sequence's"}
                    </div>
                </SubwayStop>
                {
                    env === 'ALL' &&
                    <>
                        <SubwayStop status={
                            ValidationWorkflowStatus === 0 ? 'not_started' : 
                            ValidationWorkflowStatus === 1 ? 'warning' :
                            ValidationWorkflowStatus === 2 ? 'progress' : 
                            ValidationWorkflowStatus === 3 ? 'complete' : 'error'
                        }>
                            ASPA validation workflow
                            <div slot="details">
                                {
                                    ValidationWorkflowStatus === 0 ? "Campaign Not deployed yet" :
                                    ValidationWorkflowStatus === 1 ? "ASPA Validation workflow hasn't succeeded since deployment" :
                                    ValidationWorkflowStatus === 2 ? "ASPA Validation workflow is In-Progress" :
                                    ValidationWorkflowStatus === 3 ? "ASPA Validation workflow has completed successfully" : 
                                    "ASPA Validation workflow hasn't processed " + (ValidationStatus.error || "")
                                }
                            </div>
                        </SubwayStop>
                        <SubwayStop status={
                            PreProcessingWorkflowStatus === 0 ? 'not_started' : 
                            PreProcessingWorkflowStatus === 1 ? 'warning' :
                            PreProcessingWorkflowStatus === 2 ? 'progress' : 
                            PreProcessingWorkflowStatus === 3 ? 'complete' : 'error'
                        }>
                            ASPA Pre-processing workflow
                            <div slot="details">
                                {
                                    PreProcessingWorkflowStatus === 0 ? "Campaign Not deployed yet" :
                                    PreProcessingWorkflowStatus === 1 ? "ASPA Pre-Processing workflow hasn't succeeded since deployment" :
                                    PreProcessingWorkflowStatus === 2 ? "ASPA Pre-Processing workflow is In-Progress" :
                                    PreProcessingWorkflowStatus === 3 ? "ASPA Pre-Processing workflow has completed successfully" : 
                                    "ASPA Pre-Processing workflow hasn't processed " + (PreProcessingStatus.error || "")
                                }
                            </div>
                        </SubwayStop>
                        <SubwayStop status={
                            releaseStatus !== "Deployed" || ValidationWorkflowStatus !== 3 || PreProcessingWorkflowStatus !== 3 ? "not_started" :
                            subwayStatus.segmentValidationState === ValidationState.loading || subwayStatus.recsStatus === ValidationState.loading ? "progress" :
                            !allNebulaWorkflowValidation ? "warning" :
                            allNebulaWorkflowValidation && subwayStatus.recommendations.generated === 0 && hasNebulaDelayed(modifiedOn) ? "error" :
                            allNebulaWorkflowValidation && subwayStatus.recommendations.generated === 0 ? "progress" : "complete"
                        }>
                            Nebula workflows
                            <div slot="details">
                                {   
                                    releaseStatus !== "Deployed" || ValidationWorkflowStatus !== 3 || PreProcessingWorkflowStatus !== 3 ? "Workflow's not started yet" :
                                    subwayStatus.segmentValidationState === ValidationState.loading || subwayStatus.recsStatus === ValidationState.loading ? "Loading..." :
                                    !allNebulaWorkflowValidation ? "Found validation error's for the Campaign" :
                                    allNebulaWorkflowValidation && subwayStatus.recommendations.generated === 0 && hasNebulaDelayed(modifiedOn) ? "There could be Nebula failures, Please raise an ICM" :
                                    allNebulaWorkflowValidation && subwayStatus.recommendations.generated === 0 ? ("Workflow's are in progress, " + getNextETA(modifiedOn)) : "No Nebula failures found"
                                }
                            </div>
                        </SubwayStop>
                        <SubwayStop status={isCampaignHasRecs ? subwayStatus.recsStatus : "not_started"}>
                            Recommendations
                            <div slot="details">
                                {
                                    (!isCampaignHasRecs || subwayStatus.recsStatus === 'not_started') ? "Recommendations are not generated yet" :
                                    sectionViewState === SectionView.recsView ? "See details" :
                                    <Button appearance="link" onClick={() => setSectionView(SectionView.recsView)}>See details</Button>
                                }
                            </div>
                        </SubwayStop>
                    </>
                }
            </Subway>

            <Card className={'cardView'} style={{display: sectionViewState === SectionView.segmentView ? 'block' : 'none'}}>
                <span slot="heading">
                    Segments and Sequences
                </span>
                <div className="cardContent">
                    {
                        subwayStatus.segmentValidationState === ValidationState.loading ? <InlineStatus type="in-progress">Loading...</InlineStatus>
                        :
                        subwayStatus.segments.length === 0 ? <div className="noSegmentFound"><InlineStatus type="error">No matching Segments found</InlineStatus></div>
                        :
                        <>
                            Found below {subwayStatus.segments.length > 1 ? "matching Segments" : "segment"} for the campaign at MSX
                            <table className="segmentTableHeader">
                                <tr>
                                    <th>Segment Name</th>
                                    <th>Assignment Rule: Users found</th>
                                    <th>Attached Sequence</th>
                                </tr>
                                {
                                    subwayStatus.segments.map((segment, index) => {
                                        return (
                                            <tr className="segmentTableContent">
                                                <td>{segment.segmentName}</td>
                                                <td>{
                                                    renderAssignmentRules(subwayStatus.assignmentRules[segment.segmentName], subwayStatus.assignmentRuleStatus[segment.segmentName])
                                                }</td>
                                                <td>{
                                                    segment.sequence !== undefined ? 
                                                    <InlineStatus type="complete">{segment.sequence}</InlineStatus> : 
                                                    <InlineStatus type="error">Sequence not attached</InlineStatus>
                                                }</td>
                                            </tr>
                                        )
                                    })
                                }
                            </table>
                        </>
                    }
                </div>
            </Card>

            <Card className={'cardView'} style={{display: sectionViewState === SectionView.recsView ? 'block' : 'none'}}>
                <span slot="heading">
                    Recommendations
                </span>
                <div className="cardContent">
                    <table className="RecsInfoTable">
                        <tr>
                            <td><InlineStatus type="complete">Total records uploaded to Datacart</InlineStatus></td>
                            <td>{inCartLength}</td>
                        </tr>
                        <tr>
                            <td><InlineStatus type={subwayStatus.recommendations.generated > 0 ? "complete" : "error"}>Total Generated Recommendations </InlineStatus></td>
                            <td>{subwayStatus.recommendations.generated}</td>
                        </tr>
                        <tr>
                            <td><InlineStatus type={subwayStatus.recommendations.unassigned === 0 ? "complete" : "needs-attention"}>Recommendations assigned to <i># Nebula-Integration-User</i></InlineStatus></td>
                            <td>{subwayStatus.recommendations.unassigned}</td>
                        </tr>
                        <tr><td><br/></td></tr>
                        <tr className="needBorder">
                            <th>Segmentation Info</th>
                            <th>Rec's Count</th>
                        </tr>
                        {
                            Object.keys(subwayStatus.recommendations.segmentInfo).filter(sn => sn !== "Unsegmented").map((segmentName) => {
                                return <tr className="needBorder">
                                    <td><InlineStatus type="complete">{segmentName}</InlineStatus></td>
                                    <td>{subwayStatus.recommendations.segmentInfo[segmentName]}</td>
                                </tr>;
                            })
                        }
                        <tr className="needBorder">
                            <td><InlineStatus type={subwayStatus.recommendations.segmentInfo['Unsegmented'] > 0 ? "unknown" : "complete"}>Unsegmented</InlineStatus></td>
                            <td>{subwayStatus.recommendations.segmentInfo['Unsegmented']}</td>
                        </tr>
                    </table>
                </div>
            </Card>
        </div>
    )
}

const ReleaseLiveDashboardC: React.FC<ILiveDashboardProps> = (props: ILiveDashboardProps) => {

    const location = useLocation();
    const { workflowName } = location.state;

    const releaseDashboard = useSelector((state: RootState) => state.releaseDashboard);
    const userSalesRoles = useSelector((state: RootState) => state.userSalesRoles);

    const [isDeploy, setIsDeploy] = useState(true);

    const firstRow = props.releaseRows.sort((a, b) => parseInt(a.cells.field1 + "") - parseInt(b.cells.field1 + ""))[0];
    const currentVersion = firstRow ? firstRow.cells.field1 : "1";
    const devReleaseStatus = props.releaseRows.find((x:any) => x.cells.field1 === currentVersion)?.cells.field2;
    const uatReleaseStatus = props.releaseRows.find((x:any) => x.cells.field1 === currentVersion)?.cells.field3;
    const prodReleaseStatus = props.releaseRows.find((x:any) => x.cells.field1 === currentVersion)?.cells.field4;

    const devConfiguration: Campaign = useSelector((state: RootState) => state.releaseData.devConfiguration);
    const uatConfiguration: Campaign = useSelector((state: RootState) => state.releaseData.uatConfiguration);
    const prodConfiguration: Campaign = useSelector((state: RootState) => state.releaseData.prodConfiguration);

    return (
        <Dialog
            heading={`Campaign Live Dashboard | ${workflowName}`}
            className={'live-dashboard-dialog'}
            open={props.viewState}
            onHeAfterHide={() => {props.setViewState(false)}}
        >        
            <Accordion expand-mode="single">
                {!userSalesRoles.isApprover && !userSalesRoles.isSpaUser && (props.env === 'ALL' || props.env === 'DEV') &&
                    <AccordionItem expanded={props.env === 'DEV'}>
                        <span slot="heading">Dev - Release Status</span>
                        {RenderSubwayStatus(props.env, releaseDashboard.devStatus, setIsDeploy, new Date(devConfiguration.modifiedDate), (devConfiguration.inCart || []).length, devConfiguration.postDeployInfo, devReleaseStatus)}
                    </AccordionItem>
                }
                {(props.env === 'ALL' || props.env === 'UAT') &&
                    <AccordionItem expanded={props.env === 'UAT' || ((userSalesRoles.isApprover || userSalesRoles.isSpaUser) && props.env === "ALL")}>
                        <span slot="heading">UAT - Release Status</span>
                        {RenderSubwayStatus(props.env, releaseDashboard.uatStatus, setIsDeploy,  new Date(uatConfiguration.modifiedDate), (uatConfiguration.inCart || []).length, uatConfiguration.postDeployInfo, uatReleaseStatus)}
                    </AccordionItem>
                }
                {(props.env === 'ALL' || props.env === 'PROD') &&
                    <AccordionItem expanded={props.env === 'PROD'}>
                        <span slot="heading">Prod - Release Status</span>
                        {RenderSubwayStatus(props.env, releaseDashboard.prodStatus, setIsDeploy,  new Date(prodConfiguration.modifiedDate), (prodConfiguration.inCart || []).length, prodConfiguration.postDeployInfo, prodReleaseStatus)}
                    </AccordionItem>
                }
            </Accordion>
            
            {
                /* footer code */
                props.buttonText && props.env !== 'ALL' && 
                <>
                    <Divider slot="footer"/>
                    <Button slot="footer" style={{"marginRight":"20px", "marginTop": "10px"}} appearance="primary" 
                        disabled={!isDeploy}
                        onClick={() => {
                            props.setViewState(false);
                            props.onDeploy && props.onDeploy();
                        }}
                    >
                        {props.buttonText}
                    </Button>
                </>
            }
        </Dialog>
    );
};
export const ReleaseLiveDashboard = withErrorBoundary("ReleaseLiveDashboard", ReleaseLiveDashboardC);