import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { 
  getMatchingSegments_dev, getMatchingSegments_prod, getMatchingSegments_uat, getRecommendationsCount_dev, 
  getRecommendationsCount_prod, getRecommendationsCount_uat, getWorkflowsBySubKeys_dev, 
  getWorkflowsBySubKeys_prod, getWorkflowsBySubKeys_uat, RecsDetails, SegmentDetails,
  getAssignmentRuleDetails_dev, getAssignmentRuleDetails_uat, getAssignmentRuleDetails_prod
} from "../../../services/SPAAdmin/ReleaseDashboard.api";

export enum ValidationState {
  valid = 'complete',
  loading = 'progress',
  invalid = 'error',
  failed = 'warning',
  notStarted = 'not_started'
}

export interface subwayStatus {
  datasetValidationState: ValidationState;
  segmentValidationState: ValidationState;
  inValidDatasets: string[];
  sequenceValidationState: ValidationState;
  datasetsToValidate: string[];
  segments: {
    segmentName: string;
    sequence?: string;
  }[];
  assignmentRules: { [key: string]: number };
  assignmentRuleStatus: {[key:string]: ValidationState};
  recsStatus: ValidationState;
  recommendations: {
    generated: number;
    unassigned: number;
    segmentInfo: {[key:string]: number};
  };
}

export interface ReleaseDashboardState {
  devStatus: subwayStatus;
  uatStatus: subwayStatus;
  prodStatus: subwayStatus;
}

const initialState: ReleaseDashboardState = {
  devStatus: {
    datasetValidationState: ValidationState.loading,
    segmentValidationState: ValidationState.loading,
    sequenceValidationState: ValidationState.loading,
    assignmentRuleStatus: {},
    inValidDatasets: [],
    datasetsToValidate: [],
    segments: [],
    assignmentRules: {},
    recsStatus: ValidationState.notStarted,
    recommendations: {
      generated: 0,
      unassigned: 0,
      segmentInfo: {}
    }
  },
  uatStatus: {
    datasetValidationState: ValidationState.loading,
    segmentValidationState: ValidationState.loading,
    sequenceValidationState: ValidationState.loading,
    assignmentRuleStatus: {},
    inValidDatasets: [],
    datasetsToValidate: [],
    segments: [],
    assignmentRules: {},
    recsStatus: ValidationState.notStarted,
    recommendations: {
      generated: 0,
      unassigned: 0,
      segmentInfo: {}
    }
  },
  prodStatus: {
    datasetValidationState: ValidationState.loading,
    segmentValidationState: ValidationState.loading,
    sequenceValidationState: ValidationState.loading,
    assignmentRuleStatus: {},
    inValidDatasets: [],
    datasetsToValidate: [],
    segments: [],
    assignmentRules: {},
    recsStatus: ValidationState.notStarted,
    recommendations: {
      generated: 0,
      unassigned: 0,
      segmentInfo: {}
    }
  }
}

const isDataSetsValid = (workflows: any[], datasetsToValidate: string[]) => {
  if (datasetsToValidate.length === 0) return [];
  const items = workflows.filter((x: any) => {
    return x.tenantName === "Solution Play Accelerator(SPA)" && x.workFlowType === "Dataset" && x.status === "Published"
  }).map((x: any) => x.config);
  const WorkflowIds = items.map((x: any) => x.WorkflowId);
  const inValidDatasets = datasetsToValidate.filter((x: any) => !WorkflowIds.includes(x));
  return items.filter((x: any) => inValidDatasets.includes(x.WorkflowId)).map((x: any) => x.WorkflowName);
}

const isSegmentsAreValid = (segments: SegmentDetails[]) => {
  return segments.length > 0;
}

const isSequenceAreAttached = (segments: SegmentDetails[]) => {
  const invalidSeq = segments.filter((x: any) => x._msdyn_sequence_value === undefined || x._msdyn_sequence_value === null);
  return segments.length > 0 && invalidSeq.length === 0;
}

const getSegmentObject = (segment: any, idx: number) => {
  return {
    segmentName: segment.msdyn_name,
    sequence: segment["aa.msdyn_name"]
  }
}

const parseAssignmentRule = (assignmentRules: any[]) => {
  const arules: { [key:string]: any} = {};
  assignmentRules.forEach((y: any) => {
    const [segmentName, assignmentRule, count] = [y['msdyn_name'], y['af.msdyn_name'], y['count']];
    if (!Object.hasOwn(arules, segmentName)) {
      arules[segmentName] = {};
    }
    if (!Object.hasOwn(arules[segmentName], assignmentRule)) {
      arules[segmentName][assignmentRule] = 0;
    }
    arules[segmentName][assignmentRule] += parseInt(count + "");
  });
  return arules;
}

const countRecommendations = (recs: RecsDetails[]) => {
  const recsCount = {
    generated: 0,
    unassigned: 0,
  };
  const segmentInfo: {[key:string]:number} = {'Unsegmented' : 0 };
  recs.forEach((x: RecsDetails) => {
    recsCount.generated += x.recs_count;
    if (x.segmentName === undefined || x.segmentName === null || x.segmentName.toLowerCase() === "test segment") {
      segmentInfo['Unsegmented'] += x.recs_count;
    } else {
      if (!Object.hasOwn(segmentInfo, x.segmentName)) {
        segmentInfo[x.segmentName] = 0;
      }
      segmentInfo[x.segmentName] += x.recs_count;
    }
    if (x.ownerName === undefined || x.ownerName === null || x.ownerName.toLowerCase().includes("nebula-integration-user")) {
      recsCount.unassigned += x.recs_count;
    }
  });
  return {...recsCount, segmentInfo};
}

export const ReleaseDashboardSlice = createSlice({
  name: 'releaseDashboard',
  initialState,
  reducers: {
    setDevDataSetsToValidate: (state, action: PayloadAction<string[]>) => {
      state.devStatus.datasetsToValidate = action.payload;
    },
    setUATDataSetsToValidate: (state, action: PayloadAction<string[]>) => {
      state.uatStatus.datasetsToValidate = action.payload;
    },
    setProdDataSetsToValidate: (state, action: PayloadAction<string[]>) => {
      state.prodStatus.datasetsToValidate = action.payload;
    },
    resetAllState: (state) => {
      state.devStatus = {...initialState.devStatus};
      state.uatStatus = {...initialState.uatStatus};
      state.prodStatus = {...initialState.prodStatus};
    },
    setAssignmentRuleStatusToLoading: (state, action: PayloadAction<{env: 'dev'|'uat'|'prod', segmentName: string}>) => {
      if (action.payload.env === 'dev') {
        state.devStatus.assignmentRuleStatus[action.payload.segmentName] = ValidationState.loading;
      } else if (action.payload.env === 'uat') {
        state.uatStatus.assignmentRuleStatus[action.payload.segmentName] = ValidationState.loading;
      } else {
        state.prodStatus.assignmentRuleStatus[action.payload.segmentName] = ValidationState.loading;
      }
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getWorkflowsBySubKeys_dev.pending, (state, action) => {
        state.devStatus.datasetValidationState = ValidationState.loading;
      })
      .addCase(getWorkflowsBySubKeys_dev.fulfilled, (state, action) => {
        if (!(action.payload instanceof Array) || (action.payload.length === 0)) {
          state.devStatus.datasetValidationState = ValidationState.failed;
          return;
        }
        const inValidDatasetNames = isDataSetsValid(action.payload, state.devStatus.datasetsToValidate);
        if (inValidDatasetNames.length === 0 && state.devStatus.datasetsToValidate.length > 0) {
          state.devStatus.datasetValidationState = ValidationState.valid;
        } else {
          state.devStatus.datasetValidationState = ValidationState.invalid;
          state.devStatus.inValidDatasets = inValidDatasetNames;
        }
      })
      .addCase(getWorkflowsBySubKeys_dev.rejected, (state, action) => {
        state.devStatus.datasetValidationState = ValidationState.failed;
      })
      .addCase(getWorkflowsBySubKeys_uat.pending, (state, action) => {
        state.uatStatus.datasetValidationState = ValidationState.loading;
      })
      .addCase(getWorkflowsBySubKeys_uat.fulfilled, (state, action) => {
        if (!(action.payload instanceof Array) || (action.payload.length === 0)) {
          state.uatStatus.datasetValidationState = ValidationState.failed;
          return;
        }
        const inValidDatasetNames = isDataSetsValid(action.payload, state.uatStatus.datasetsToValidate);
        if (inValidDatasetNames.length === 0 && state.uatStatus.datasetsToValidate.length > 0) {
          state.uatStatus.datasetValidationState = ValidationState.valid;
        } else {
          state.uatStatus.datasetValidationState = ValidationState.invalid;
          state.devStatus.inValidDatasets = inValidDatasetNames;
        }
      })
      .addCase(getWorkflowsBySubKeys_uat.rejected, (state, action) => {
        state.uatStatus.datasetValidationState = ValidationState.failed;
      })
      .addCase(getWorkflowsBySubKeys_prod.pending, (state, action) => {
        state.prodStatus.datasetValidationState = ValidationState.loading;
      })
      .addCase(getWorkflowsBySubKeys_prod.fulfilled, (state, action) => {
        if (!(action.payload instanceof Array) || (action.payload.length === 0)) {
          state.prodStatus.datasetValidationState = ValidationState.failed;
          return;
        }
        const inValidDatasetNames = isDataSetsValid(action.payload, state.prodStatus.datasetsToValidate);
        if (inValidDatasetNames.length === 0 && state.prodStatus.datasetsToValidate.length > 0) {
          state.prodStatus.datasetValidationState = ValidationState.valid;
        } else {
          state.prodStatus.datasetValidationState = ValidationState.invalid;
          state.devStatus.inValidDatasets = inValidDatasetNames;
        }
      })
      .addCase(getWorkflowsBySubKeys_prod.rejected, (state, action) => {
        state.prodStatus.datasetValidationState = ValidationState.failed;
      })
      // Segment and Sequence Validation
      .addCase(getMatchingSegments_dev.pending, (state, action) => {
        state.devStatus.segmentValidationState = ValidationState.loading;
        state.devStatus.segments = [];
      })
      .addCase(getMatchingSegments_dev.fulfilled, (state, action) => {
        if (!(action.payload instanceof Array)) {
          state.devStatus.segmentValidationState = ValidationState.failed;
          state.devStatus.segments = [];
          return;
        }
        state.devStatus.segments = action.payload.map((x: any, idx) => getSegmentObject(x, idx));
        state.devStatus.segmentValidationState = isSegmentsAreValid(action.payload) ? ValidationState.valid : ValidationState.invalid;
        if (isSequenceAreAttached(action.payload)) {
          state.devStatus.sequenceValidationState = ValidationState.valid;
        } else {
          state.devStatus.sequenceValidationState = ValidationState.invalid;
        }
      })
      .addCase(getMatchingSegments_dev.rejected, (state, action) => {
        state.devStatus.segmentValidationState = ValidationState.failed;
        state.devStatus.sequenceValidationState = ValidationState.failed;
        state.devStatus.segments = [];
      })
      .addCase(getMatchingSegments_uat.pending, (state, action) => {
        state.uatStatus.segmentValidationState = ValidationState.loading;
        state.uatStatus.segments = [];
      })
      .addCase(getMatchingSegments_uat.fulfilled, (state, action) => {
        if (!(action.payload instanceof Array)) {
          state.uatStatus.segmentValidationState = ValidationState.failed;
          state.uatStatus.sequenceValidationState = ValidationState.failed;
          state.uatStatus.segments = [];
          return;
        }
        state.uatStatus.segments = action.payload.map((x: any, idx) => getSegmentObject(x, idx));
        state.uatStatus.segmentValidationState = isSegmentsAreValid(action.payload) ? ValidationState.valid : ValidationState.invalid;
        if (isSequenceAreAttached(action.payload)) {
          state.uatStatus.sequenceValidationState = ValidationState.valid;
        } else {
          state.uatStatus.sequenceValidationState = ValidationState.invalid;
        }
      })
      .addCase(getMatchingSegments_uat.rejected, (state, action) => {
        state.uatStatus.segmentValidationState = ValidationState.failed;
        state.uatStatus.sequenceValidationState = ValidationState.failed;
        state.uatStatus.segments = [];
      })
      .addCase(getMatchingSegments_prod.pending, (state, action) => {
        state.prodStatus.segmentValidationState = ValidationState.loading;
        state.prodStatus.segments = [];
      })
      .addCase(getMatchingSegments_prod.fulfilled, (state, action) => {
        if (!(action.payload instanceof Array)) {
          state.prodStatus.segmentValidationState = ValidationState.failed;
          state.prodStatus.sequenceValidationState = ValidationState.failed;
          return;
        }
        state.prodStatus.segments = action.payload.map((x: any, idx) => getSegmentObject(x, idx));
        state.prodStatus.segmentValidationState = isSegmentsAreValid(action.payload) ? ValidationState.valid : ValidationState.invalid;
        if (isSequenceAreAttached(action.payload)) {
          state.prodStatus.sequenceValidationState = ValidationState.valid;
        } else {
          state.prodStatus.sequenceValidationState = ValidationState.invalid;
        }
      })
      .addCase(getMatchingSegments_prod.rejected, (state, action) => {
        state.prodStatus.segmentValidationState = ValidationState.failed;
        state.prodStatus.sequenceValidationState = ValidationState.failed;
        state.prodStatus.segments = [];
      })
      .addCase(getRecommendationsCount_dev.pending, (state, action) => {
        state.devStatus.recsStatus = ValidationState.loading;
      })
      .addCase(getRecommendationsCount_dev.fulfilled, (state, action) => {
        const recs = countRecommendations(action.payload);
        state.devStatus.recommendations = recs;
        state.devStatus.recsStatus = recs.generated > 0 ? ValidationState.valid : ValidationState.failed;
      })
      .addCase(getRecommendationsCount_dev.rejected, (state, action) => {
        state.devStatus.recsStatus = ValidationState.failed;
      })
      .addCase(getRecommendationsCount_uat.pending, (state, action) => {
        state.uatStatus.recsStatus = ValidationState.loading;
      })
      .addCase(getRecommendationsCount_uat.fulfilled, (state, action) => {
        const recs = countRecommendations(action.payload);
        state.uatStatus.recommendations = recs;
        state.uatStatus.recsStatus = recs.generated > 0 ? ValidationState.valid : ValidationState.failed;
      })
      .addCase(getRecommendationsCount_uat.rejected, (state, action) => {
        state.uatStatus.recsStatus = ValidationState.failed;
      })
      .addCase(getRecommendationsCount_prod.pending, (state, action) => {
        state.prodStatus.recsStatus = ValidationState.loading;
      })
      .addCase(getRecommendationsCount_prod.fulfilled, (state, action) => {
        const recs = countRecommendations(action.payload);
        state.prodStatus.recommendations = recs;
        state.prodStatus.recsStatus = recs.generated > 0 ? ValidationState.valid : ValidationState.failed;
      })
      .addCase(getRecommendationsCount_prod.rejected, (state, action) => {
        state.prodStatus.recsStatus = ValidationState.failed;
      })
      .addCase(getAssignmentRuleDetails_dev.fulfilled, (state, action) => {
        const result = parseAssignmentRule(action.payload.data);
        if (result === undefined || Object.keys(result).length === 0) {
          state.devStatus.assignmentRuleStatus[action.payload.segmentName] = ValidationState.failed;
          return;
        }
        const assignmentRules = {...state.devStatus.assignmentRules};
        Object.keys(result).forEach((segmentName) => assignmentRules[segmentName] = result[segmentName]);
        state.devStatus.assignmentRules = assignmentRules;
        state.devStatus.assignmentRuleStatus[action.payload.segmentName] = ValidationState.valid;
      })
      .addCase(getAssignmentRuleDetails_uat.fulfilled, (state, action) => {
        const result = parseAssignmentRule(action.payload.data);
        if (result === undefined || Object.keys(result).length === 0) {
          state.uatStatus.assignmentRuleStatus[action.payload.segmentName] = ValidationState.failed;
          return;
        }
        const assignmentRules = {...state.uatStatus.assignmentRules};
        Object.keys(result).forEach((segmentName) => assignmentRules[segmentName] = result[segmentName]);
        state.uatStatus.assignmentRules = assignmentRules;
        state.uatStatus.assignmentRuleStatus[action.payload.segmentName] = ValidationState.valid;
      })
      .addCase(getAssignmentRuleDetails_prod.fulfilled, (state, action) => {
        const result = parseAssignmentRule(action.payload.data);
        if (result === undefined || Object.keys(result).length === 0) {
          state.prodStatus.assignmentRuleStatus[action.payload.segmentName] = ValidationState.failed;
          return;
        }
        const assignmentRules = {...state.prodStatus.assignmentRules};
        Object.keys(result).forEach((segmentName) => assignmentRules[segmentName] = result[segmentName]);
        state.prodStatus.assignmentRules = assignmentRules;
        state.prodStatus.assignmentRuleStatus[action.payload.segmentName] = ValidationState.valid;
      })
  }
});

export const releaseDashboardActions = ReleaseDashboardSlice.actions;

export const ReleaseDashboardReducer = ReleaseDashboardSlice.reducer;