import { createAsyncThunk } from "@reduxjs/toolkit"
import { WorkflowSummary } from "../../model/workflows/Workflow.model";
import { RootState } from "../../root-redux/RootState";
import { getRequest } from "../utils/GetRequest";
import { postRequest } from "../utils/PostRequest";

export const getWorkflowsBySubKeys_dev = createAsyncThunk<WorkflowSummary[], { subKey: string }, { state: RootState }>('releaseDashboard/getWorkflowsBySubKey_dev', async ({ subKey }, { getState }) => {
    const response = await getRequest<WorkflowSummary[]>(`dev/workflows/details?subKeys=${subKey}`)
    return response.data
});

export const getWorkflowsBySubKeys_uat = createAsyncThunk<WorkflowSummary[], { subKey: string }, { state: RootState }>('releaseDashboard/getWorkflowsBySubKey_uat', async ({ subKey }, { getState }) => {
    const response = await getRequest<WorkflowSummary[]>(`uat/workflows/details?subKeys=${subKey}`)
    return response.data
});

export const getWorkflowsBySubKeys_prod = createAsyncThunk<WorkflowSummary[], { subKey: string }, { state: RootState }>('releaseDashboard/getWorkflowsBySubKey_prod', async ({ subKey }, { getState }) => {
    const response = await getRequest<WorkflowSummary[]>(`prod/workflows/details?subKeys=${subKey}`)
    return response.data
});

export type matchingSegmentsPayload = {
    campaignTag?: string,
    orgName: string,
    solutionArea: string,
    solutionPlay: string,
    salesMotion: string,
    segment?: string,
    isMultiSegment: boolean
}

export class SegmentDetails {
    msdyn_name!: string
    msdyn_segmentid?: string
    msdyn_entityfilter?: string
    _msdyn_sequence_value?: string
    "aa.msdyn_name"?: string
}

export class RecsDetails {
    recs_count!: number
	segmentName?: string
	ownerName?: string
}

export const getMatchingSegments_dev = createAsyncThunk<any, matchingSegmentsPayload, { state: RootState }>('releaseDashboard/getMatchingSegments_dev', async (payload, { getState }) => {
    const response = await postRequest<SegmentDetails[], any>('dev/campaigntaxonomy/fetch/SegmentForCampaign', payload);
    return response.data;
});

export const getMatchingSegments_uat = createAsyncThunk<any, matchingSegmentsPayload, { state: RootState }>('releaseDashboard/getMatchingSegments_uat', async (payload, { getState }) => {
    const response = await postRequest<SegmentDetails[], any>('uat/campaigntaxonomy/fetch/SegmentForCampaign', payload);
    return response.data;
});

export const getMatchingSegments_prod = createAsyncThunk<any, matchingSegmentsPayload, { state: RootState }>('releaseDashboard/getMatchingSegments_prod', async (payload, { getState }) => {
    const response = await postRequest<SegmentDetails[], any>('prod/campaigntaxonomy/fetch/SegmentForCampaign', payload);
    return response.data;
});

const parseRecResponse = (responseData: any) => {
    if (responseData instanceof Array) {
        return responseData.map((rec) => {
            return {
                recs_count: rec.recs_count,
                segmentName: rec["segmentid@OData.Community.Display.V1.FormattedValue"],
                ownerName: rec["owner_id@OData.Community.Display.V1.FormattedValue"]
            } as RecsDetails;
        });
    }
    return [] as RecsDetails[];
}

export const getRecommendationsCount_dev = createAsyncThunk<RecsDetails[], string, { state: RootState }>('releaseDashboard/getRecommendationsCount_dev', async (campaignTag, { getState }) => {
    const response = await getRequest<any>(`dev/campaigntaxonomy/fetch/recommendationCount/${campaignTag}`);
    return parseRecResponse(response.data["value"]);
});

export const getRecommendationsCount_uat = createAsyncThunk<RecsDetails[], string, { state: RootState }>('releaseDashboard/getRecommendationsCount_uat', async (campaignTag, { getState }) => {
    const response = await getRequest<any>(`uat/campaigntaxonomy/fetch/recommendationCount/${campaignTag}`);
    return parseRecResponse(response.data["value"]);
});

export const getRecommendationsCount_prod = createAsyncThunk<RecsDetails[], string, { state: RootState }>('releaseDashboard/getRecommendationsCount_prod', async (campaignTag, { getState }) => {
    const response = await getRequest<any>(`prod/campaigntaxonomy/fetch/recommendationCount/${campaignTag}`);
    return parseRecResponse(response.data["value"]);
});

export const getAssignmentRuleDetails_dev = createAsyncThunk<any, string, { state: RootState }>('releaseDashboard/getAssignmentRuleDetails_dev', async (segmentName, { getState }) => {
    const response = await postRequest<any, any>(`dev/campaigntaxonomy/fetch/AssignmentRuleForCampaign`, { segmentName });
    return {segmentName, data: response.data}
});

export const getAssignmentRuleDetails_uat = createAsyncThunk<any, string, { state: RootState }>('releaseDashboard/getAssignmentRuleDetails_uat', async (segmentName, { getState }) => {
    const response = await postRequest<any, any>(`uat/campaigntaxonomy/fetch/AssignmentRuleForCampaign`, { segmentName });
    return {segmentName, data: response.data}
});

export const getAssignmentRuleDetails_prod = createAsyncThunk<any, string, { state: RootState }>('releaseDashboard/getAssignmentRuleDetails_prod', async (segmentName, { getState }) => {
    const response = await postRequest<any, any>(`prod/campaigntaxonomy/fetch/AssignmentRuleForCampaign`, { segmentName });
    return {segmentName, data: response.data}
});