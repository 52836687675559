import { useSelector } from "react-redux"
import { NavPanel } from "../nav-panel/NavPanel"
import { RootRouter } from "../root-router/RootRouter"
import './app-body-styles.css'
import { RootState } from "../../root-redux/RootState"
import { FeedbackDialog } from "../../shared-components/feedback/FeedbackDialog"
import { Initialize } from "../../feature-components/spa-admin/utils/Initialize"

export const AppBody: React.FC = () => {
    const isNavPanelOpen = useSelector((state: RootState) => state.nav.isNavPanelOpen)
    
    return (
        <div className='app-body'>
            <FeedbackDialog />
            <NavPanel />
            <div className={`root-container ${isNavPanelOpen ? '': 'collapsed'}`}>
                <RootRouter />
                <Initialize />
            </div>            
        </div>
    )
}