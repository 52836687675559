import { createAsyncThunk } from "@reduxjs/toolkit"
import { Attribute, Campaign, Config, ReviewList } from "../../model/SPAModels/campaigns/campaign.Model"
import { RootState } from "../../root-redux/RootState"
import { postRequest } from "../utils/PostRequest"
import { v4 as uuidv4 } from 'uuid';
import { getRequest } from "../utils/GetRequest";
import { Helper } from "../../feature-components/spa-admin/utils/Help";
import { postRequestReleaseManagement } from "../utils/PostRequestReleaseManagement";
import { SPA_APP_ID } from "../../feature-components/spa-admin/SPAConstants";
import { getWorkflowsBySubKeys } from "../GetWorkflows.api";
import { setPendingReview } from "../../feature-components/spa-admin/redux/CampaignDataCart.redux";
import { resetResultList } from "../../feature-components/spa-admin/redux/CampaignQueryBuilder.redux";
import { sendNotificationAboutNewLaunch, sendNotificationAboutUpdate, sendSPANotificationKickOff } from "./MEONotification.api";

const checkAndGetWorkflowsBySubkey = (dispatch: any, subKey: string) => {
    dispatch(getWorkflowsBySubKeys({ subKeys: subKey }));
    dispatch(setPendingReview(false));
    dispatch(resetResultList());
}

export const postCampaignInSilent = createAsyncThunk<any, void, { state: RootState }>('campaignById/postCampaignInSilent', async (_, {getState, dispatch, rejectWithValue}) => {
    return saveCampaignDetails(getState, dispatch, rejectWithValue);
});

export const postCampaignDetails = createAsyncThunk<any, void, { state: RootState }>('campaignById/postCampaign', async (_, {getState, dispatch, rejectWithValue}) => {
    return saveCampaignDetails(getState, dispatch, rejectWithValue);
});
export const refreshPreSummary = createAsyncThunk<any,{id:string}, { state: RootState }>('/refreshPreSummary', async (configurationDetails:any,{getState}) => {

    const env = getState().env.selectedEnvItem;
var response = await getRequest<string>(`${env}/campaigns/${configurationDetails.id}/preSummary`);
    return response?.data;

});
const sendSPANotification = (dispatch: any, newWorkflowId: string, notificationType: any) => {
    dispatch(notificationType({ workflowId: newWorkflowId })).unwrap().then((result: any) => {
        if(result) {
            console.log(result);
        }
    }).catch((err: any) => {
        console.log(err);
    })
}

const saveCampaignDetails = async (getState: any, dispatch: any, rejectWithValue: any) => {

    const env = getState().env.selectedEnvItem;
    
    const campaignState: Campaign = getState().campaignById.campaign!;
    const editCampaignFlag = getState().campaignById.editCampaignFlag;
    let campaign: Campaign = new Campaign();
    const s = getState().campaignById.summary;
    let createdOn = s['CreatedOn']!;
    let isNewCampaign = false;

    if (!campaignState) {
        const newWorkflowId = uuidv4().toString();
        campaign.id = uuidv4().toString();
        campaign.partitionKey = campaign.id;
        campaign.subscriptionKey = s["SubscriptionKey"]!;

        campaign.config = {
            workflowId: newWorkflowId,
            workflowName: s["CampaignName"]
        } as Config;

        campaign.type = 'Campaign';
        campaign.status = 'Draft';
        campaign.data = '';                                                                                                                                                               
        campaign.version = 1;
        campaign.environment = env;
        campaign.family = "";
        campaign.workFlowType = "Campaign";
        campaign.selfServeVersion = "";
        campaign.tenantName = getState().appPersonas.list.find((app: any) => app.appId.map((id: string) => id.toLowerCase()).includes(SPA_APP_ID))?.appName!;
        campaign.typeOfMerge = "Default";
        campaign.defaultMergeRule = "Default";
        campaign.isActionPublish = false;
        campaign.description = s["Description"];
        campaign.tags = s["Tags"]!;
        campaign.createdBy = getState().userDetails.localAccountId;
        campaign.dataIngestion =[];
        campaign.preProcessing = [];
        campaign.delta = [];
        campaign.publish = [];
        campaign.filters = [];
        campaign.inCart = [];
        campaign.outOfCart = [];
        campaign.dataSets = [];
        campaign.reviewList = [];

        isNewCampaign = true;

        // send a mail to spa v-team, About ***new Campaign*** This will help teams to initiate comms to local Area teams to action on SPA. 
        sendSPANotification(dispatch, newWorkflowId, sendNotificationAboutNewLaunch);
        sendSPANotification(dispatch, newWorkflowId, sendSPANotificationKickOff);
    } 
    else {
        Object.assign(campaign, campaignState);
        if(editCampaignFlag && campaignState.status === 'Published')
        {
            campaign.id = uuidv4().toString();
            campaign.partitionKey = campaign.id;
            campaign.version = campaignState.version + 1;
            createdOn = Helper.getCurrentDatetime();
            campaign.status = 'Draft';
            campaign.postDeployInfo = [];
            campaign.dataIngestion = [];
            campaign.preProcessing = [];
            campaign.delta = [];
            campaign.publish = [];
            campaign.outOfCart = [];
        }
        campaign.config = {
            workflowId: campaignState.config.workflowId,
            workflowName: s["CampaignName"]
        } as Config;

        // send a mail to spa v-team, About ***update of Campaign*** This will help teams to initiate comms to local Area teams to action on SPA. 
        sendSPANotification(dispatch, campaignState.config.workflowId, sendNotificationAboutUpdate);
    }
    campaign.description = s["Description"];
    campaign.tags = s["Tags"]!;
    campaign.startOnDate = s["StartOn"]!;
    campaign.expiryDate = s["ExpiryDate"]!;
    campaign.actionByDate = s["ActionByDate"]!;
    campaign.modifiedDate = Helper.getCurrentDatetime()
    campaign.modifiedBy = getState().userDetails.localAccountId;
    const completeRList = campaign.reviewList ? [...campaign.reviewList] : [];

    // Summary Attributes Handler
    const summary: Attribute[] = [];
    const configFields = getState().campaignConfig.campaignConfig?.layoutFields.filter((f: any) => f.type !== "hidden").map((f: any) => f.name);
    for(const k in s) {
        if (configFields?.includes(k)) {
            summary.push({ key: k, value: s[k]! });
        }
    }
    summary.push({ key: 'CampaignType', value: s['CampaignType']!});
    summary.push({ key: 'CreatedOn', value: createdOn});
    campaign.summary = summary;

    if (!getState().userSalesRoles.isApprover) {
        const response = await postRequest<string, Campaign>(`${env}/campaigns`, campaign);
        const data: any = response.data;
        if ((data["Code"] || "").startsWith('ERR')) {
            return rejectWithValue(data);
        }
        if (isNewCampaign) {
            checkAndGetWorkflowsBySubkey(dispatch, campaign.subscriptionKey);
        }
        dispatch(setPendingReview(false));
        return response.data;
    }
    
    // ReviewList Handler
    let reviewList: ReviewList|undefined = undefined;
    const idx = completeRList.findIndex(rList => rList.attributes.some(attr => {
        return attr.key === 'Area' && attr.value === getState().userSalesRoles.userDetails?.area!
    }));
    const addList = getState().campaignById.addReviewList.filter((id: string) => !campaign.inCart.includes(id));
    const deleteList = getState().campaignById.deleteReviewList.filter((id: string) => !campaign.outOfCart.includes(id));
    const list = addList.concat(deleteList);
    if (idx > -1) {
        const temp = completeRList[idx];
        const userIds = temp.userIds.includes(getState().userDetails.localAccountId) ? temp.userIds : ([] as string[]).concat(temp.userIds, [getState().userDetails.localAccountId]);
        reviewList = {
            userIds: userIds,
            attributes: temp.attributes,
            list: list
        }
        completeRList.splice(idx, 1);
    } else if (list.length > 0) {
        reviewList = {
            userIds: [getState().userDetails.localAccountId],
            attributes: [{key: 'Area', value: getState().userSalesRoles.userDetails?.area!}],
            list: list
        }
    }
    if (reviewList) {
        completeRList.push(reviewList);
    }
    campaign.reviewList = completeRList;

    const response = await postRequest<string, Campaign>(`${env}/campaigns`, campaign);
    const data: any = response.data;
    if ((data["Code"] || "").startsWith('ERR')) {
        return rejectWithValue(data);
    }
    checkAndGetWorkflowsBySubkey(dispatch, campaign.subscriptionKey);
    return response.data;
};

export const postCampaignDetailsForRelease = createAsyncThunk<any, Campaign, { state: RootState }>('campaignById/postCampaignDetailsForRelease', async (campaign:Campaign, {getState, dispatch, rejectWithValue}) => {

    const selectedEnv = getState().env.selectedEnvItem ;
    var environment = campaign.environment === "" || campaign.environment === null ? selectedEnv : campaign.environment;
    const modifiedCampaign = {
        ...campaign,
        modifiedDate: Helper.getCurrentDatetime()
    };

    modifiedCampaign.modifiedBy = getState().userDetails.localAccountId;
    const response = await postRequestReleaseManagement<Campaign, Campaign>(`${environment}/campaigns`, modifiedCampaign);
    const data: any = response.data;
    if ((data["Code"] || "").startsWith('ERR')) {
        return rejectWithValue(data);
    }
    checkAndGetWorkflowsBySubkey(dispatch, campaign.subscriptionKey);
    return response.data;

})
export const getCampaignById = createAsyncThunk<any, string, { state: RootState }>('campaignById/getCampaignById', async (id: string, {getState, dispatch, rejectWithValue}) => {
    const env = getState().env.selectedEnvItem;
    const response = await getRequest<Campaign>(`${env}/campaigns/${id}`);
    const data: any = response.data;
    if ((data["Code"] || "").startsWith('ERR')) {
        return rejectWithValue(data);
    }
    checkAndGetWorkflowsBySubkey(dispatch, response.data.subscriptionKey);
    return response.data;
})
export const getCampaignByIdandVersion_dev = createAsyncThunk<any,{workflowId:string, version:string}, { state: RootState }>('campaignByIdAndVersion_dev/getCampaignById', async (campaignDetails:any,{getState, dispatch, rejectWithValue}) => {
    const response = await getRequest<Campaign>(`dev/campaigns/${campaignDetails.version}/${campaignDetails.workflowId}`);
    const data: any = response.data;
    if ((data["Code"] || "").startsWith('ERR')) {
        return rejectWithValue(data);
    }
    checkAndGetWorkflowsBySubkey(dispatch, response.data.subscriptionKey);
    return response.data;
})
export const getCampaignByIdandVersion_uat = createAsyncThunk<any,{workflowId:string, version:string}, { state: RootState }>('campaignByIdAndVersion_uat/getCampaignById', async (campaignDetails:any,{getState, dispatch, rejectWithValue}) => {
    const response = await getRequest<Campaign>(`uat/campaigns/${campaignDetails.version}/${campaignDetails.workflowId}`);
    const data: any = response.data;
    if ((data["Code"] || "").startsWith('ERR')) {
        return rejectWithValue(data);
    }
    checkAndGetWorkflowsBySubkey(dispatch, response.data.subscriptionKey);
    return response.data;
})
export const getCampaignByIdandVersion_prod = createAsyncThunk<any,{workflowId:string, version:string}, { state: RootState }>('campaignByIdAndVersion_prod/getCampaignById', async (campaignDetails:any,{getState, dispatch, rejectWithValue}) => {
    const response = await getRequest<Campaign>(`prod/campaigns/${campaignDetails.version}/${campaignDetails.workflowId}`);
    const data: any = response.data;
    if ((data["Code"] || "").startsWith('ERR')) {
        return rejectWithValue(data);
    }
    checkAndGetWorkflowsBySubkey(dispatch, response.data.subscriptionKey);
    return response.data;
})
export const getADBRunStatus = createAsyncThunk<any, string, { state: RootState }>('preSummary/getADBRunStatus', async (id: string, {getState, dispatch, rejectWithValue}) => {
    const response = await getRequest<Campaign>(`dev/campaigns/${id}/getRunStatus`);
    const data: any = response.data;
    if ((data["Code"] || "").startsWith('ERR')) {
        return rejectWithValue(data);
    }
    return response.data;
})
