import { CommandBar, Button, Icon } from "../../workflows/WorkflowsScope";
import { AppDispatch, RootState } from "../../../root-redux/RootState";
import { useDispatch, useSelector } from "react-redux";
import { addConnection } from "../ReleaseData.redux";
import { setReleaseStatus } from "../Releases.redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";
import { ErrorNotification } from "../../notifications/ErrorNotification";
import { SuccessNotification } from "../../notifications/SuccessNotification";
import { withErrorBoundary } from "../../../shared-components/ErrorBoundary";
import { ConnectionsCollection } from "../../../model/connections/ConnectionsCollection.model";
import { propagateConnectionReleaseManagement } from "../../../services/PropagateConnections.api";
import { publishConfigurationReleaseManagement } from "./ConfigurationApi";
import { ReleaseLiveDashboard } from '../../spa-admin/edit-campaign/ReleaseLiveDashboard';

const ReleaseConfigurationActionsDEVC: React.FC<any> = (props: any) => {
  const workflowId = props.id;
  const workFlowType = props.type;
  const devConfiguration = props.devConfiguration;
  const subscriptionKey = props.subscriptionKey;
  const actionedVersion = props.selectedVersion;
  const selectedReleaseIds = props.selectedReleaseIds;
  const uatConnections = useSelector((state: RootState) => state.releaseData.uatConnections );
  const prodConnections = useSelector((state: RootState) => state.releaseData.prodConnections );
  const userSalesRoles = useSelector((state: RootState) => state.userSalesRoles);
  const dispatchApp = useDispatch<AppDispatch>();
  const dispatch = useDispatch();

  const [disabledevDeploy, setDisableDevDeploy] = useState(true);
  const releaseRows = props.releaseRows;
  const [showErrorNotif, setShowErrorNotif] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [successText, setSuccessText] = useState("");
  const [showSuccessNotif, setShowSuccessNotif] = useState(false);
  const [dashboardView, setDashboardView] = useState(false);

  useEffect(() => {
    const devStatus = releaseRows.find(
      (x: any) => x.cells.field1 == actionedVersion
    )?.cells.field2;
    setDisableDevDeploy(
      actionedVersion ? (devStatus == "Draft" ? false : true) : true
    );
  }, [actionedVersion]);

  const handleDevDeployment = () => {

    if((!devConfiguration || devConfiguration.dataIngestion.length == 0))
    {
      setErrorText("Please onboard the data sources before deploying");
      setShowErrorNotif(true);
      setDisableDevDeploy(true);
    }
    else
    {
      var nullConnectionPropagation = {} as ConnectionsCollection;
      nullConnectionPropagation.adlsConnections = [];
      nullConnectionPropagation.blobConnections = []; 
      nullConnectionPropagation.sqlConnections = [];
      nullConnectionPropagation.cosmosConnections = [];
      nullConnectionPropagation.eventConnections = [];
      nullConnectionPropagation.topicConnections = [];
      nullConnectionPropagation.sqlaadConnections = [];
      nullConnectionPropagation.apiConnections = [];

      dispatchApp(publishConfigurationReleaseManagement({id:workflowId,type:workFlowType,env: "dev"}))
      .then(unwrapResult)
      .then((result) => {
        if (result == true) {
          if(uatConnections[subscriptionKey] === null)
          {
            dispatchApp(propagateConnectionReleaseManagement({payload:nullConnectionPropagation, env:"uat", subscriptionKey: subscriptionKey}));
            dispatch(addConnection({subskey: subscriptionKey, input: nullConnectionPropagation, env:"uat"}));
          }
          if(prodConnections[subscriptionKey] === null)
          {
            dispatchApp(propagateConnectionReleaseManagement({payload:nullConnectionPropagation, env:"prod", subscriptionKey: subscriptionKey}));
            dispatch(addConnection({subskey: subscriptionKey, input: nullConnectionPropagation, env:"prod"}));
          }
          setShowSuccessNotif(true);
          setSuccessText("DEV Deployment completed");
         
          dispatch(
            setReleaseStatus({
              version: actionedVersion,
              status: "Published",
              env: "dev",
            })
          );
          
          setDisableDevDeploy(true);
        } else {
          setErrorText("Dev deployment failed.");
          setShowErrorNotif(true);
          setDisableDevDeploy(true);
        }
      });
    }
    
  };

  const onDeploy = () => {
    setDashboardView(false);
    if (userSalesRoles.isSpaUser || userSalesRoles.isSpaAdmin) {
      handleDevDeployment();
    } else {
      setErrorText(
        "You do not have permission to deploy workflow in dev"
      );
      setShowErrorNotif(true);
    }
  }

  return (
    <div
      style={{
        display: "flex",
        padding: "4px",
        fontSize: "14px",
        alignItems: "center",
        marginRight: "12px",
        marginLeft: "12px",
      }}
    >
      <div>
        <span style={{ fontWeight: "700", marginRight: "8px" }}>DEV</span>
      </div>
      <div>
        <SuccessNotification
          successNotif={showSuccessNotif}
          setsuccesnotif={setShowSuccessNotif}
          successText={successText}
        />{" "}
      </div>
      <div>
        {" "}
        <ErrorNotification
          errorNotif={showErrorNotif}
          seterrornotif={setShowErrorNotif}
          errorText={errorText}
        />{" "}
      </div>

      <div>
        <CommandBar>
          <Button
            style={{ border: "none", outline: "none", boxShadow: "none" }}
            disabled={disabledevDeploy || selectedReleaseIds.length != 1}
            appearance="command"
            onClick={() => onDeploy()}
          >
            <Icon slot="start" name="rocket"></Icon>
            Deploy
          </Button>
        </CommandBar>
      </div>

      <ReleaseLiveDashboard env="DEV" buttonText="Deploy to Dev" viewState={dashboardView} releaseRows={releaseRows} setViewState={setDashboardView} onDeploy={() => onDeploy()}/>
    </div>
  );
};
export const ReleaseConfigurationActionsDEV = withErrorBoundary("ReleaseActionsDEV", ReleaseConfigurationActionsDEVC);
