import * as React from "react";
import {
  createScope,
  tab,
  tabs,
  tabPanel,
  tooltip,
  checkbox,
  accordion,
  accordionItem,
  dataGrid,
  pageTitle,
  toggle,
  pagination,
  text,
  progressRing,
  divider,
  commandBar,
  button,
  icon,
  searchBox,
  flyInPanel,
  messageBar,
  formatDate,
  progress,
  badge,
  option,
  hoverCard,
  card,
  partnerCenterTheme,
  menu,
  menuItem,
  breadcrumb,
  breadcrumbItem,
  taskMenu,
  taskItem,
  pushPane,
  paneGroup,
  dropdown,
  quickNav,
  textField,
  select,
  dialog,
  subway,
  subwayStop,
  textArea,
  quickStartCard,
  radio,
  radioGroup,
  tree,
  treeItem,
  skeleton,
  datePicker,
  callout

} from "@harmony/enablers/react";


const scope = createScope({
    basePath: "https://harmonyenablers.microsoft.com/npm/@harmony/enablers/6.15.0",
  theme: partnerCenterTheme,
  reactInstance: React,
});
const PageTitleView = scope.forReact(pageTitle);
const Text = scope.forReact(text);
const ProgressRing = scope.forReact(progressRing);
const Divider = scope.forReact(divider);
const CommandBar = scope.forReact(commandBar);
const Button = scope.forReact(button);
const Icon = scope.forReact(icon);
const SearchBox = scope.forReact(searchBox);
const FlyInPanel = scope.forReact(flyInPanel);
const MessageBar = scope.forReact(messageBar);
const Pagination = scope.forReact(pagination);
const Toggle = scope.forReact(toggle);
const Tooltip = scope.forReact(tooltip);
const DataGridView = scope.forReact(dataGrid);
const Tabs = scope.forReact(tabs);
const Tab = scope.forReact(tab);
const Accordion = scope.forReact(accordion);
const AccordionItem = scope.forReact(accordionItem);
const Checkbox = scope.forReact(checkbox);
const TabPanel = scope.forReact(tabPanel);
const FormatDate = scope.forReact(formatDate);
const ProgressIndicator = scope.forReact(progress);
const Badge = scope.forReact(badge);
const Option = scope.forReact(option);
const Hovercard = scope.forReact(hoverCard);
const Card = scope.forReact(card);
const MenuView = scope.forReact(menu);
const MenuItemView = scope.forReact(menuItem);
const BreadCrumbView = scope.forReact(breadcrumb);
const BreadCrumbItemView = scope.forReact(breadcrumbItem);
const TaskMenuView = scope.forReact(taskMenu);
const TaskItemView = scope.forReact(taskItem);
const PushPaneView = scope.forReact(pushPane);
const PaneGroupView = scope.forReact(paneGroup);
const DropDown = scope.forReact(dropdown);
const QuickNav = scope.forReact(quickNav);
const TextField = scope.forReact(textField);
const Select = scope.forReact(select);
const Dialog = scope.forReact(dialog);
const Subway = scope.forReact(subway);
const SubwayStop = scope.forReact(subwayStop);
const TextArea = scope.forReact(textArea);
const QuickStartCard = scope.forReact(quickStartCard);
const Radio = scope.forReact(radio);
const RadioGroup = scope.forReact(radioGroup);
const Tree = scope.forReact(tree);
const TreeItem = scope.forReact(treeItem);
const Skeleton = scope.forReact(skeleton);
const DatePicker = scope.forReact(datePicker);
const Callout = scope.forReact(callout);

export {
  DatePicker,
  Text,
  TabPanel,
  Checkbox,
  Tabs,
  Tab,
  AccordionItem,
  Accordion,
  DataGridView,
  Tooltip,
  PageTitleView,
  Toggle,
  ProgressRing,
  Divider,
  CommandBar,
  Button,
  Icon,
  SearchBox,
  FlyInPanel,
  MessageBar,
  Pagination,
  FormatDate,
  ProgressIndicator,
  Badge,
  Option,
  Card,
  Hovercard,
  MenuView,
  MenuItemView,
  BreadCrumbView,
  BreadCrumbItemView,
  TaskMenuView,
  TaskItemView,
  PushPaneView,
  PaneGroupView,
  DropDown,
  QuickNav,
  TextField,
  Select,
  Dialog,
  Subway,
  SubwayStop,
  TextArea,
  QuickStartCard,
  Radio,
  RadioGroup,
  Tree,
  TreeItem,
  Skeleton,
  Callout
};
