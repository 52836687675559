import { Connection } from "./Connection.model";

export class AdlsConnection extends Connection
{
    public appClientID!: string
    public appClientSecret!: string
    public adlsStorageAccount!: string
    public adlsContainer!: string
    public fileFormat!: string
    public msiClientId!: string
}