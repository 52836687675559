import * as Constants from "./Constants";

var fileReadSupported = false;
if (window.File && window.FileReader) {
  fileReadSupported = true;
}

export const ValidateFileData = (csvData: string): string => {
  const rows = csvData.split(/\r?\n/).filter(row => row.trim() !== '');
  // Check if the CSV is empty
  if (rows.length === 1) {
    return Constants.INPUT_BLANK;
  }

  // Check if the CSV has headers
  const headers = rows[0].split(',');
  if (headers.length !== 3 || (!headers.includes('TPID') || !headers.includes('Estimated Revenue') || !headers.includes('Additional Insights(Optional)'))) {
    return Constants.INPUT_BAD_HEADERS;
  }
  else if((headers[0] !== 'TPID' || headers[1] !=='Estimated Revenue' || headers[2] !== 'Additional Insights(Optional)'))
    return Constants.INPUT_HEADERS_BAD_ORDER;

  // Check if the CSV has too many rows
  if (rows.length > Constants.MAX_ROWS + 1) { // Add 1 to account for the header row
    const inputTooLongMessage = `There are too many rows in the file. There is currently a limit of ${Constants.MAX_ROWS} entries per file.`;
    return inputTooLongMessage;
  }

  // If we make it to this point, the file is valid
  return Constants.INPUT_VALID;
}

export const ValidateReassignmentFileData = (csvData: string): string => {
  const rows = csvData.split(/\r?\n/).filter(row => row.trim() !== '');
  // Check if the CSV is empty
  if (rows.length === 1) {
    return Constants.INPUT_BLANK;
  }

  // Check if the CSV has headers
  const headers = rows[0].split(',');
  if (headers.length !== 3 || (!headers.includes('Recommendation Id') || !headers.includes('New Owner alias') || !headers.includes('Campaign Tag(Optional)'))) {
    return Constants.INPUT_BAD_HEADERS;
  }
  else if((headers[0] !== 'Recommendation Id' || headers[1] !=='New Owner alias' || headers[2] !== 'Campaign Tag(Optional)'))
    return Constants.INPUT_HEADERS_BAD_ORDER;

  // Check if the CSV has too many rows
  if (rows.length > Constants.MAX_ROWS + 1) { // Add 1 to account for the header row
    const inputTooLongMessage = `There are too many rows in the file. There is currently a limit of ${Constants.MAX_ROWS} entries per file.`;
    return inputTooLongMessage;
  }

  // If we make it to this point, the file is valid
  return Constants.INPUT_VALID;
}
export const ValidateFile = (file: any): string => {
  //first check if the file exists
  if (!file || file.name === "") {
    return Constants.INPUT_NOFILE;
  }
  //next check if the file name ends with .xlsx
  var ext = file.name.substring(file.name.lastIndexOf(".") + 1, file.name.length).toLowerCase();
  if (ext !== "csv") {
    return Constants.INPUT_NOT_CSV;
  }
  //if we determined before that we cannot read files, we need to stop here
  if (!fileReadSupported) {
    return Constants.INPUT_VALID;
  }
  return Constants.INPUT_VALID;
}
