import { useRef, useState, useEffect } from "react";
import {
  Button,
  Icon,
  ProgressRing,
  TextArea,
  TextField,
  Tooltip
} from "../../../common/HarmonyEnablers";
import "../../onboarding-styles.css";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../root-redux/RootState";
import { withErrorBoundary } from "../../../shared-components/ErrorBoundary";
import { ValidateFile, ValidateReassignmentFileData } from "../../../services/utils/ExcellValidation";
import * as Constants from "../../../services/utils/Constants";
import { textArea, textField } from "@harmony/enablers/react";
import { CampaignHeader } from "../edit-campaign/EditCamapaignHeader";
import { NavMenuItemType } from "../../../model/NavMenu.model";
import { useNavigate } from "react-router-dom";
import { InformationText } from "../../../shared-components/info-text/InformationText";
import informationConfig from "../../../shared-content/inputDescription";
import { HelpTexts, SPA_SubscriptionKey } from "../SPAConstants";
import { getAccountId, getBulkReassignmentList, postReassignmentRequest, runBulkReassignmentJob, uploadReassignmentFile } from "../../../services/BulkReassignment.api";
import { v4 as uuidv4 } from 'uuid';
import { Environment } from "../../../model/releases/Release.model";
import { getReassignmentUploadTemplate } from "../../../services/GetUploadReassignmentTemplate.api";

const UploadReassignmentC = () => {
  // redux status
    const isNavPanelOpen = useSelector((state: RootState) => state.nav.isNavPanelOpen);
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate()
    const environment = useSelector((state: RootState) => state.env.selectedEnvItem);
    const fileUploadRef = useRef<textField>(null);
    const reassignmentNameRef = useRef<textField>(null);
    const descriptionRef = useRef<textArea>(null);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [submitClicked, setSubmitClicked] = useState(false);
    const [reassignmentInserted, setReassignmentInserted] = useState(true);
    const [clickedButtonId, setClickedButtonId] = useState("");
    const [uploadedFileBlobUri, setUploadedFileBlobUri] = useState<string>("");
    const [reassignId, setReassignId] = useState<string>("");

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const fileInput = event.target;
      const file = event.target.files![0];
      setSelectedFile(file);
      const fileValidationStatus = ValidateFile(file);
      if (fileValidationStatus !== Constants.INPUT_VALID) {
        fileUploadRef.current?.setCustomValidity(fileValidationStatus);
        setSelectedFile(null);
        event.target.value = "";
        return;
      }
      const reader = new FileReader();
      reader.onload = (event) => {
        const csvData = event.target?.result?.toString();
        if (!csvData) {
          return;
        }
        const fileDataValidationStatus = ValidateReassignmentFileData(csvData);
        if (fileDataValidationStatus !== Constants.INPUT_VALID) {
          fileUploadRef.current?.setCustomValidity(fileDataValidationStatus);
          setSelectedFile(null);
          fileInput.value = "";
          return;
        }

        fileUploadRef.current?.setCustomValidity('');
        setSelectedFile(file);
      }
      reader.readAsBinaryString(file);
    };

    const downloadTemplate = async () => {
      setClickedButtonId("downloadTemplate");
      await dispatch(getReassignmentUploadTemplate(null));
    };

    const handleClear = (event: any): void => {
      event.preventDefault();
      setSubmitClicked(false);
      setClickedButtonId("");
      setSelectedFile(null);
    };

    const handleSubmit = async(event: any) => {
      if (selectedFile) {
        setSubmitClicked(true);
        setClickedButtonId("upload-file");
        const formData = new FormData();
        formData.append('file', selectedFile);
        const reassignId = uuidv4().toString();
        setReassignId(reassignId);
        formData.append('reassignId', reassignId);
        let uploadFile = await dispatch(uploadReassignmentFile({payload: formData}));
        setUploadedFileBlobUri(uploadFile.payload);
        event.preventDefault();
      }
    };

    const onClickNext = async () => {
      setClickedButtonId("next"); 
      let isValid = true;
      if(reassignmentNameRef == undefined || reassignmentNameRef.current?.value == "") {
        reassignmentNameRef.current?.setCustomValidity("Bulk Reassignment Name is empty");
        isValid = false;
      }
      if(fileUploadRef == undefined || fileUploadRef.current?.value == "") {
        fileUploadRef.current?.setCustomValidity("Please upload the file for reassignment");
        isValid = false;
      }
      if(!isValid) {
        return;
      }
      var environments: Environment[] = [];
      let accountId = (await dispatch(getAccountId()).unwrap());
      let reassignObj = {
        id: reassignId,
        partitionKey : reassignId,
        subscriptionKey: SPA_SubscriptionKey,
        name: reassignmentNameRef.current?.value || "",
        status: "InProgress",
        environment: environment,
        createdBy: accountId,
        modifiedDate: new Date().toISOString(),
        description: descriptionRef.current?.value || "",
        inputFileUri : uploadedFileBlobUri
    }      
    const formData = new FormData();
    if (selectedFile) {
      formData.append('file', selectedFile);
    }
    formData.append("reassignObj", JSON.stringify(reassignObj));    
    // dispatch(insertReassignmentData({payload: formData})).unwrap().then((result) => {
    // if(result.Code != "ERR122") {
    //     //dispatch(runBulkReassignmentJob({ reassignId: reassignObj.id }));
    //     dispatch(postReassignmentRequest(reassignObj)).unwrap().then(() => {
    //     dispatch(getBulkReassignmentList());
    //     _onNavMenuItemClick(NavMenuItemType.Configuration);
    //     });
    // }
    // else {
    //   setReassignmentInserted(false);
    // }
    // });
    dispatch(postReassignmentRequest(reassignObj)).unwrap().then((result) => {
      if(result.Code != "ERR122") {
          dispatch(runBulkReassignmentJob(reassignObj.id));
          dispatch(getBulkReassignmentList());
          _onNavMenuItemClick(NavMenuItemType.Configuration);
      }
      else {
        setReassignmentInserted(false);
      }
    });
    };

    
    const _onNavMenuItemClick = (navMenuItemType: NavMenuItemType) => {
      navigate("/" + NavMenuItemType[navMenuItemType]);
    };

    return (
      <>
      <div className={`spa-sticky-header ${isNavPanelOpen ? '': 'collapsed'}`}>
        <CampaignHeader title={"Reassign"} currentPage={1}/>

        <div className="spa-informationtext">
          <InformationText text={informationConfig.reassignSummary} type="Heading"/>
        </div>
        {clickedButtonId == "next" && !reassignmentInserted ? 
              <>
                <div style={{ color: "red", fontSize: "15px", marginTop:'20px', marginLeft:'30px'}}>
                  File Upload Failed. Please try again or check with SPA Engineering team &nbsp;
                </div> </> : <></>}
      </div>

      <div className='workflows-wrapper' style={{marginTop: "220px"}}>
        <table className='dataset-fileupload-table workflow-form-table  workflow-basics-table' role='presentation' style={{marginBottom: '80px'}}>
        <tr>
            <td>
              Name 
              <Icon className='required-icon' name='actioncenterasterisk'></Icon>
                    <Icon name='info' id={'ReassignmentName'} style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }}></Icon>
                    <Tooltip anchor={'ReassignmentName'}>
                        {HelpTexts.ReassignmentName}
                    </Tooltip>
                </td>
                <td>
                    <TextField name={'ReassignmentName'} label={'ReassignmentName'} className={'Text-fields'}  ref={reassignmentNameRef} placeholder={`Provide name for reassignment here`}/>
                </td>
        </tr>
        <tr>
            <td>
                Description
                <Icon name='info' id={'Description'} style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }}></Icon>
                <Tooltip anchor={'Description'}>
                    {HelpTexts.datasetDescription}
                </Tooltip>
            </td>
            <td>
                <TextArea name={'Description'} label={'Description'} className={'Text-fields'} ref={descriptionRef} placeholder={`Enter Description here`}/>
            </td>
        </tr>
        <tr>
          <td>
          <div className="dataset-template">
            Please select a file to upload
            <Icon className='required-icon' name='actioncenterasterisk'></Icon>
          </div>
          </td>
          <td className="fileupload-inputfield">
            <div className="fileupload-inputtext">
              <TextField style={{width: '350px', cursor: 'pointer'}} clearable value={selectedFile? selectedFile.name : ""} ref={fileUploadRef} placeholder="Select a file to upload"
                onClick={() => { document.getElementById("file-input")!.click() }} onHeClear={handleClear}/>
            </div>
            <div className="fileupload-input-file" id={'custom-upload-btn'}>
              <input type="file" id="file-input" onChange={handleFileChange} 
                hidden />
              <label htmlFor="file-input" style={{ marginLeft: "10px", color: 'var(--he-color-primary-700)' }}>
              <Icon className="custom-button" name="upload"/>
              </label>
            </div>
            <Tooltip anchor="custom-upload-btn">Select a file to upload</Tooltip>
            <div style={{marginLeft: '7px'}}>
              <Button
                id='upload-file'
                appearance="stealth"
                className="fileupload-input-file"
                onClick = {handleSubmit}
                disabled={selectedFile == null || submitClicked}
                style={{ color: 'var(--he-color-primary-700)' }}>
                <Icon className="custom-button" label = "Upload a file" name="accept"/>
              </Button>
              <Tooltip anchor="upload-file">Submit</Tooltip>
            </div>
            {clickedButtonId === "upload-file" && uploadedFileBlobUri == "" && 
                <div style={{marginLeft: '10px', marginTop: '12px'}}>
                <ProgressRing labelPlacement="end" label="Uploading file..." indeterminate></ProgressRing>;
                </div>
              }
          </td>
          
          <div style={{ marginTop: "10px", marginRight: "7px"}}>
            <Icon name='info' id={'downloadTemplate'} style={{ cursor: "pointer", color: "#0078D4" }}></Icon>
            <Tooltip anchor="downloadTemplate">The standard template can be downloaded here</Tooltip>
            <Button appearance="link" onClick={downloadTemplate} style={{ color: "#0b62e4" }}>Download Template</Button>
          </div>
        </tr>        
        </table>
          <div className={`workflow-footer ${isNavPanelOpen ? '': 'collapsed'}`}>
              <Button type='button' appearance='secondary' onClick={() => _onNavMenuItemClick(NavMenuItemType.Configuration)} style={{ marginRight: '20px' }}>Previous</Button>
              <Button type='button' appearance='primary' disabled= {uploadedFileBlobUri == "" || reassignmentNameRef?.current?.value == ""} onClick={() => onClickNext()}>Next</Button> 
          </div>
      </div>
    </>
    );  
};
      
export const UploadReassignment = withErrorBoundary("Dataset Creation", UploadReassignmentC);