import { createAsyncThunk } from "@reduxjs/toolkit"
import { getRequest } from "../utils/GetRequest"
import { RootState } from "../../root-redux/RootState"

export const getUserSalesRoles = createAsyncThunk<any, void, { state: RootState }>('userRole/getUserSalesRoles', async (_, {getState, rejectWithValue}) => {
    const env = getState().env.selectedEnvItem;
    const aadId = getState().userDetails.localAccountId;
    if (aadId) {
        const response = await getRequest<any>(`${env}/taxonomy/userinfo/${aadId}?settings=ASPASegment,ASPASolutionPlay`);
        if ((response.data["Code"] || "").startsWith('ERR')) {
            return rejectWithValue({ message: "Unable to Fetch userinfo: " + JSON.stringify(response.data) });
        }
        return response.data;
    } else {
        return rejectWithValue({ message: "Local account id not found" });
    }
});