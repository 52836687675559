import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Utils as QbUtils, BasicConfig, JsonTree } from '@react-awesome-query-builder/ui';
import { v4 as uuidv4 } from 'uuid';
import { AppDispatch, RootState } from '../../../root-redux/RootState';
import { withErrorBoundary } from '../../../shared-components/ErrorBoundary';
import { Button, Card, CommandBar, Icon, Menu, Dialog, MessageBar,
         ProgressRing, Select, Option, Dropdown, MenuItem } from '../SpaAdminScope';
import FilterQuery from './Components/FilterQuery';
import { ViewQueryResults } from './Components/ViewQueryResults';
import { SaveQueryPopUp } from './Popups/SaveQueryPopup';
import { getWorkflowsBySubKeys } from '../../../services/GetWorkflows.api';
import { fetchCampaignFilterCount, postCampaignFilterList } from '../../../services/SPAAdmin/CampaignsList.api';
import { CampaignFilterQuery } from '../../../model/SPAModels/campaigns/campaign.Model';
import { Helper } from '../utils/Help';
import { setInCartAndOutOfCart, addFilter } from '../redux/CampaignById.redux';
import { postCampaignDetails } from '../../../services/SPAAdmin/CampaignById.api';
import { RequestStatus } from '../../../model/ServiceRequestStatus.model';
import { WorkflowSummary } from '../../../model/workflows/Workflow.model';
import { changePageNumber, setMaxPageNumber, resetResultList, setModelId } from '../redux/CampaignQueryBuilder.redux';
import { CampaignTypesEnum, ConfigurationType, DSE_CONFIGS, DSE_SEARCH_CONFIG, MessageTypes, NA, QueryBuilderFields, SEGMENT_FILTERS_MAP } from '../SPAConstants';
import { getAllRecords } from '../../../services/SPAAdmin/CampaignDataCart.api';

import './CampaignQuerybuilder.styles.css';
import { updateSoftDelete } from '../redux/CampaignDataCart.redux';
import { Environment, Release } from '../../../model/releases/Release.model';
import { postRelease } from '../../../services/PostRelease.api';
import { addNewWorkflow } from '../../workflows/workflow-by-id-redux/WorkflowById.actions.redux';
import { UserMode, setDatasetCache } from '../redux/CacheHandler.redux';
import { NavMenuItemType } from '../../../model/NavMenu.model';
import { useLocation, useNavigate } from 'react-router-dom';
import { setCurrentDatasetFileUploadState } from '../../workflows/workflows-redux/DatasetFileUpload.redux';
import { DatasetFileUploadModel, DatasetResponseType } from '../../../model/workflows/DatasetFileUpload.model';
import { FileUploadPopUp } from '../dataset/FileUploadPopup';
import { DatasetReleasePopup } from '../../release-management/Configuration/DatasetReleasePopup';


interface IDataCart {
    refreshCart: () => void,
    nextPageEvent: (idx: number) => void;
}

const QueryBuilderC: React.FC<IDataCart> = ({ refreshCart, nextPageEvent }) => {

    // redux states
    const dispatchApp = useDispatch<AppDispatch>();
    const navigate = useNavigate()
    const campaignById = useSelector((state: RootState) => state.campaignById);
    const userSalesRoles = useSelector((state: RootState) => state.userSalesRoles);
    const userDetails = useSelector((state: RootState) => state.userDetails);
    const filteredCampaign = useSelector((state: RootState) => state.filteredCampaign);
    const workflowsBySubKey = useSelector((state: RootState) => state.workflowsBySubKeys);
    const environment = useSelector((state: RootState) => state.env.selectedEnvItem);

    // local states
    const [selectedDataset, setSelectedDataset] = useState<any>(undefined);
    const [selectedModelId, setSelectedModelId] = useState<string|undefined>(undefined);
    const [selectedWorkflowSummary, setselectedWorkflowSummary] = useState<WorkflowSummary|undefined>(undefined);
    const [currentJSONTreeItem, setCurrentJSONTreeItem] = useState<string>('');
    const [attributesList, setAttributesList] = useState<{ [key: string]: string }>({});
    const [workflowName, setWorkflowName] = useState<string>('');
    const [message, setMessage] = useState<{type: MessageTypes, message: string, isOpen: boolean}|undefined>(undefined);
    const [openCreateNewDataset, setOpenCreateNewDataset] = useState(false);

    const [payload, setPayload] = useState<any>({
        Filters: [
            {
                id: uuidv4().toString(),
                Name: 'Default', // TODO
                Source: '',
                SqlFilter: ``,
                JsonQueryStringFilter: '',
                FilterToDisplay: ''
            }
        ],
        InCart: [],
        OutOfCart: [],
        Query: ``,
        QueryType: 1,
        PageNum: 1,
        Fields: {}
    });
    const [renderViewResults, setRenderViewResults] = useState<boolean>(false);
    const [selectedRows, setSelectedRows] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [showQB, setShowQB] = useState<boolean>(true);
    const [loadingQB, setLoadingQB] = useState<boolean>(true);
    const [selectStatement, setSelectStatement] = useState<string>('');
    const [openSaveQueryPopup, setOpenSaveQueryPopup] = useState<boolean>(false);
    const [key, setKey] = useState(0);
    const [isEditQuery, setIsEditQuery] = useState<boolean>(false);
    const [editqueryId, setEditqueryId] = useState<string>('');
    const [editqueryName, setEditqueryName] = useState<string>('');
    const [isAllRecordDialogOpen, setIsAllRecordDialogOpen] = useState<boolean>(false);

    useEffect(() => {
        if (workflowsBySubKey.status === RequestStatus.succeeded) {
            setLoading(false);
        }
    }, [workflowsBySubKey]);

    // methods
    const parseJsonTree = (jsonTree: string) => {
        const parsedTree: JsonTree = jsonTree ? JSON.parse(jsonTree) : '';
        return parsedTree;
    }

    // tree state config
    const [state, setState] = useState({
        tree: QbUtils.checkTree(QbUtils.loadTree(!Helper.isEmpty(parseJsonTree(currentJSONTreeItem)) ? parseJsonTree(currentJSONTreeItem) : { id: QbUtils.uuid(), type: 'group' }), BasicConfig),
        config: BasicConfig
    });

    const addWhereCondition = (key: string|undefined, value: string|undefined, list: string[]) => {
        const field = Object.keys(attributesList).find(k => k.replaceAll(' ', '').toLowerCase() === key?.toLowerCase());
        if (field && value) list.push(`${field} = '${value}'`);
        return list;
    }

    const getSegmentQuery = () => {
        const campaignType = campaignById.summary['CampaignType'] || "" ;
        var segmentQuery = '';
        if ( campaignType === CampaignTypesEnum.MultiSegment) {
            return '1=1'; // return all data
        }
        else if (campaignType === CampaignTypesEnum.Enterprise || campaignType === CampaignTypesEnum.SMC){
          //  return `Segment like 'Major%' OR Segment like 'Strategic%'`;//`Org = 'Enterprise'`;
          const values = SEGMENT_FILTERS_MAP[campaignType];
            values.forEach((val,index:number) => {
                segmentQuery+= `Segment like '${val}%' ${index < values.length - 1 ? ' OR ' : ''}`;
            }); 
         } 
        else {//FOR DSE search is based on Account Tags
           return getDSESearchConfig();
        }
        return segmentQuery;
    }
    const getDSESearchConfig = () => {
        const campaignSolutionArea = campaignById.summary[QueryBuilderFields.SolutionArea] || "";
        const campaignSolutionPlay = campaignById.summary[QueryBuilderFields.SolutionPlay] || ""; 
        var dseConfigArr:DSE_SEARCH_CONFIG[] = DSE_CONFIGS[campaignSolutionArea as keyof typeof DSE_CONFIGS] as DSE_SEARCH_CONFIG[];
        var dseConfigForSolutionPlay:DSE_SEARCH_CONFIG|undefined = undefined;
        dseConfigForSolutionPlay = dseConfigArr.find((config) => config.DSE_SEARCH_SOLUTIONPLAY === campaignSolutionPlay);
        if(dseConfigForSolutionPlay === undefined) { //Specific SolutionPlay doesnt exists, check for N/A 
            dseConfigForSolutionPlay = dseConfigArr.find((config) => config.DSE_SEARCH_SOLUTIONPLAY === NA);
        }
        if(dseConfigForSolutionPlay?.DSE_SEARCH_TAG && dseConfigForSolutionPlay.DSE_SEARCH_TAG !==NA){
            return `AccountTags like '%${dseConfigForSolutionPlay.DSE_SEARCH_TAG}%'`;
        }
        if(dseConfigForSolutionPlay?.DSE_SEARCH_TAGS ){
            var accTags =''
            var tagsLength = dseConfigForSolutionPlay.DSE_SEARCH_TAGS.length;
            dseConfigForSolutionPlay.DSE_SEARCH_TAGS.forEach((tag,index) => { 
                if(tag !== NA){
                    accTags+= `AccountTags like '%${tag}%' ${index<tagsLength-1?" OR ":""}`;
                }
            })
            return accTags;
        }
        return '';
    }
    const generateQuery = (query: string) => {
        let whereClause =  (query.search('where') >= 0) ? [query.split('where')[1]] : [];
        let segmentQuery = getSegmentQuery();
        if (selectedDataset.workFlowType === 'Dataset' && !(segmentQuery === undefined || segmentQuery === null || segmentQuery.length === 0)) {
            whereClause.push(segmentQuery);
        }
        if (selectedDataset.workFlowType === 'Dataset' && selectedDataset.datasetModelType === 'ML Model') {
            whereClause = addWhereCondition('SolutionArea', campaignById.summary['SolutionArea'], whereClause);
            whereClause = addWhereCondition('SolutionPlay', campaignById.summary['SolutionPlay'], whereClause);
            // whereClause = addWhereCondition('Product', campaignById.summary['Product'], whereClause);
        }
        return `(upper(SourceId) = '${selectedDataset!.config.workflowId.toUpperCase()}'` + (whereClause.length > 0 ? (' AND (' + whereClause.join(' AND ') + ')') : '') + ')';
    }

    const getAddAllCount = (workflowSummary: WorkflowSummary|undefined = undefined) => {
        const summary = workflowSummary || selectedWorkflowSummary;
        if (summary && summary.workFlowType === 'Dataset' && summary.datasetModelType === 'File Upload') {
            let segmentQuery = getSegmentQuery();
            segmentQuery = (segmentQuery === undefined || segmentQuery === null || segmentQuery.length === 0) ? ')' : ` AND (${segmentQuery}))`;
            const sqlQuery = `(upper(SourceId) = '${summary.config.workflowId.toUpperCase()}'` + segmentQuery;
            payload.Fields = Helper.filterFields(summary!.fields);
            payload.Filters[0].SqlFilter = sqlQuery;
            payload.Query = sqlQuery;
            payload.PageNum = -1;
            dispatchApp(fetchCampaignFilterCount({ payload: payload, subKey: summary.subscriptionKey!}));
        }
    }

    const loadSummaryInfo = (workflowSummary: WorkflowSummary) => {
        setselectedWorkflowSummary(workflowSummary);
        setWorkflowName(workflowSummary!.config.workflowName);
        setLoadingQB(false);
        setAttributesList(Helper.filterFields(workflowSummary!.fields));
        setSelectStatement('Select * from ' + workflowSummary!.config.workflowName);
        setSelectedModelId(workflowSummary!.config.workflowId);
        dispatchApp(setModelId(workflowSummary!.config.workflowId));
        setPayload((prevPayload: any) => ({
            ...prevPayload,
            Fields: Helper.filterFields(workflowSummary!.fields)
        }));
        getAddAllCount(workflowSummary);
    }

    const handleInputViewNameChange = (e: any) => {
        if(e.target.value !== "" && e.target.value !== "create-new-dataset")
        {
            const selectedDatasetJSON = JSON.parse(e.target.value);
            setSelectedModelId(undefined);
            setShowQB(false);
            setLoadingQB(true);
            setSelectedDataset(selectedDatasetJSON);
            dispatchApp(resetResultList());
            const workflowSummary = workflowsBySubKey.list.find(obj => obj.modelId.toUpperCase() === selectedDatasetJSON.config.workflowId.toUpperCase());
            if (workflowSummary) {
                loadSummaryInfo(workflowSummary);
            }
            else {
                dispatchApp(getWorkflowsBySubKeys({ subKeys: selectedDatasetJSON.subscriptionKey }))
                .unwrap()
                .then(workflowSummaries => {
                    const workflowSummary = workflowSummaries.find(obj => obj.modelId.toUpperCase() === selectedDatasetJSON.config.workflowId.toUpperCase());
                    loadSummaryInfo(workflowSummary!);
                });
            }
        }
    };

    const handleAddToCart = () => {
        let temp = (campaignById.campaign?.inCart || []);
        const newInCart: string[] = [...temp];
        
        temp = (campaignById.campaign?.dataSets || [])
        const newDataSets: string[] = [...temp];
        if (selectedDataset.modelId && !newDataSets.includes(selectedDataset.modelId)) { 
            newDataSets.push(selectedDataset.modelId);
        }

        selectedRows.filter(id => !newInCart.includes(id)).forEach(id => newInCart.push(id));

        dispatchApp(setInCartAndOutOfCart({
            InCart: newInCart,
            DataSets: newDataSets
        }));

        dispatchApp(postCampaignDetails())
            .unwrap()
            .then(() => {
                setSelectedRows([]);
                dispatchApp(updateSoftDelete([]));
                refreshCart();
                nextPageEvent(1);
            });
    }

    const handleSaveQuery = (queryName: string) => {
        const temp = (campaignById.campaign?.filters || []);
        const newQueryFilter: CampaignFilterQuery[] = [...temp];

        const whereClause = QbUtils.sqlFormat(state.tree!, state.config) ? ' where ' + QbUtils.sqlFormat(state.tree!, state.config) : '';
        const query = selectStatement + whereClause;
        const sqlQuery = generateQuery(query);

        const queryObj = {
            id: uuidv4().toString(),
            name: queryName,
            source: selectedDataset!.config.workflowId,
            SqlFilter: sqlQuery,
            jsonQueryStringFilter: JSON.stringify(state.tree),
            filterToDisplay: 'string'
        };

        newQueryFilter.push(queryObj);

        dispatchApp(addFilter({
            filters: newQueryFilter
        }));

        dispatchApp(postCampaignDetails())
            .unwrap()
            .then(() => {
                handleSelectEditQuery(queryObj);
                setMessage({type: 'success', message: 'Query Saved', isOpen: true});
            });

    }

    const handleUpdateQuery = (queryId: string, queryName: string) => {
        const temp = (campaignById.campaign?.filters || []);
        const newQueryFilter: CampaignFilterQuery[] = [...temp];

        const whereClause = QbUtils.sqlFormat(state.tree!, state.config) ? ' where ' + QbUtils.sqlFormat(state.tree!, state.config) : '';
        const query = selectStatement + whereClause;
        const sqlQuery = generateQuery(query);

        setCurrentJSONTreeItem(JSON.stringify(state.tree));
                
        const newObject = { 
            id: queryId,
            name: queryName,
            source: selectedDataset!.config.workflowId,
            SqlFilter: sqlQuery,
            jsonQueryStringFilter: JSON.stringify(state.tree),
            filterToDisplay: 'string'    
        };
        const updatedQueryFilterList = newQueryFilter.map(item => item.id === newObject.id ? newObject : item);

        dispatchApp(addFilter({
            filters: updatedQueryFilterList
        }));

        dispatchApp(postCampaignDetails())
            .unwrap()
            .then(() => {
                setMessage({type: 'success', message: 'Query Saved', isOpen: true});
            });
    }

    const handleSelectEditQuery = (query: any) => {
        setCurrentJSONTreeItem(query.jsonQueryStringFilter);
        setEditqueryId(query.id);
        setEditqueryName(query.name);
        setIsEditQuery(true);
    }

    const preparePayloadWithQuery = (pageNum: number) => {
        const whereClause = QbUtils.sqlFormat(state.tree!, state.config) ? ' where ' + QbUtils.sqlFormat(state.tree!, state.config) : '';
        const query = selectStatement + whereClause;
        const jsonTree = JSON.stringify(QbUtils.getTree(state.tree) as JsonTree)
        setCurrentJSONTreeItem(jsonTree);
        const sqlQuery = generateQuery(query);
        payload.Filters[0].SqlFilter = sqlQuery;
        payload.Query = sqlQuery;
        payload.PageNum = pageNum;
        return sqlQuery;
    }

    const handleQueryReturn = (pageNum: number) => {
        preparePayloadWithQuery(pageNum);
        if (pageNum === 1 || pageNum >= filteredCampaign.maxPageNum) {
            dispatchApp(postCampaignFilterList({ payload: payload, subKey: selectedDataset!.subscriptionKey! }));
            dispatchApp(setMaxPageNumber(pageNum));
        }
        if (pageNum === 1) {
            dispatchApp(fetchCampaignFilterCount({ payload: payload, subKey: selectedDataset!.subscriptionKey!}));
        }
        setRenderViewResults(true);
    }

    const addAllRecordsToCart = () => {
        setLoading(true);
        let sqlQuery = preparePayloadWithQuery(-1);
        
        dispatchApp(getAllRecords({modelId: selectedModelId, sqlQuery: sqlQuery})).unwrap().then(() => {
            setLoading(false);
            setSelectedRows([]);
            dispatchApp(updateSoftDelete([]));
            refreshCart();
            nextPageEvent(1);
        }).catch(() => {
            setLoading(false);
            setSelectedRows([]);
        });
    }

    const handlePageChange = (e: any) => {
        dispatchApp(changePageNumber(e.detail));
        if (e.detail % 10 === 0) {
            handleQueryReturn((e.detail / 10) + 1);
        }
    }

    const handleNewQuery = () => {
        setKey(prevKey => prevKey + 1);
        setIsEditQuery(false);
        setEditqueryName('');
        setEditqueryId('');
        setCurrentJSONTreeItem('{}');
    }

    const isAddToAllDisabled = () => {
        if (selectedDataset && selectedDataset.workFlowType === 'Dataset' && selectedDataset.datasetModelType === 'File Upload' && selectedModelId !== undefined && filteredCampaign.countStatus !== RequestStatus.loading) {
            return false;
        }
        return (!renderViewResults || loading || filteredCampaign.countStatus !== RequestStatus.succeeded);
    }

    const filterDataSet = (dataSet: WorkflowSummary) => {
        return (dataSet.status === 'Published' || dataSet.status === 'InProgress') && 
               dataSet.workFlowType === 'Dataset' && 
               dataSet.config.workflowName !== 'calcdatasetforspa' &&
               (dataSet.modifiedBy === userDetails.localAccountId || userSalesRoles.isSpaAdmin);
    }

    const createNewDataset = () => {
        const newWorkflowId = uuidv4().toString();
        const environments: Environment[] = [
          { environment: "dev", isDeployed: true },  { environment: "uat", isDeployed: true },  { environment: "prod", isDeployed: false }];
      
        const releaseData : Release = {
          environments: environments,
          timestamp: "",
          releaseId: uuidv4().toString(),
          workflowId: newWorkflowId,
          version: "1",
          id: uuidv4().toString(),
          type: "New Version",
          status: "New",
          scheduleCreated: false,
        };
        dispatchApp(postRelease({payload : releaseData, env: environment}));
        dispatchApp(addNewWorkflow({workflowId: newWorkflowId, subscriptionKey: "3A2504E0-4F89-11D3-9A0C-0305E82C3302", environment, modifiedBy: "", workflowType: "Dataset"}))
        dispatchApp(setCurrentDatasetFileUploadState({fileUploadObj :{} as DatasetFileUploadModel, responseType: DatasetResponseType.Idle}));
        dispatchApp(setDatasetCache({
            datasetId: undefined,
            mode: UserMode.CREATE
        }));
        setOpenCreateNewDataset(true);
    }

    return (
        <>
            {
                message?.isOpen &&
                <MessageBar appearance={message.type} open={message.isOpen} onHeHide={() => setMessage(undefined)}>
                    {message.message}
                </MessageBar>
            }
            <Card className='spa-query-card' key={key}>
            {
                (userSalesRoles.requestStatus === RequestStatus.loading || campaignById.requestStatus === RequestStatus.loading) ?
                <div className='spa-progressring'>
                    <ProgressRing label="Loading..." indeterminate></ProgressRing>
                </div>
                :
                <>
                <span slot='heading'>
                    <CommandBar className='spa-query-card-command'>
                        <Button appearance='command' onClick={() => handleNewQuery()} disabled={loading}>
                            <Icon slot='start' name='add'></Icon>New Query
                        </Button>
                        <Button appearance='command' onClick={() => setOpenSaveQueryPopup(true)} 
                                disabled={loadingQB || loading || !JSON.stringify(QbUtils.sqlFormat(state.tree, state.config))}>
                            <Icon slot='start' name='save'></Icon>Save Query
                        </Button>
                        <Dropdown fixed-placement>
                            <Button slot="trigger" appearance='command' disabled={loadingQB || loading}>
                                <Icon slot='start' name='pencilart64'></Icon>
                                Edit Query
                            </Button>
                            <Menu>
                                {campaignById.campaign?.filters?.map((query: any) => (
                                    <MenuItem onHeChange={() => handleSelectEditQuery(query)}>{query.name}</MenuItem>
                                ))}
                            </Menu>
                        </Dropdown>
                        <Button appearance='command' onClick={() => {
                            dispatchApp(resetResultList());
                            handleQueryReturn(1);
                        }} disabled={loadingQB || loading || !JSON.stringify(QbUtils.sqlFormat(state.tree, state.config))}>
                            <Icon slot='start' name='redeye'></Icon>View results
                        </Button>
                        <Button appearance='command' onClick={() => handleAddToCart()} disabled={!renderViewResults || loading || selectedRows.length === 0}>
                            <Icon slot='start' name='shoppingcart'></Icon>Add to Cart
                        </Button>
                        <Button appearance='command' style={{marginRight: '5px'}} onClick={() => setIsAllRecordDialogOpen(true)} disabled={isAddToAllDisabled()}>
                            <Icon slot='start' name='addmedium'></Icon>Add all to Cart {filteredCampaign.totalRowCount > 0 ? `(${filteredCampaign.totalRowCount})` : ''}
                        </Button>
                        {filteredCampaign.countStatus === RequestStatus.loading && <ProgressRing style={{ '--ring-size': '25px' }} indeterminate></ProgressRing>}
                    </CommandBar>
                </span>
                {
                    openSaveQueryPopup && (
                    <SaveQueryPopUp
                        handleSaveQuery={handleSaveQuery}
                        handleUpdateQuery={handleUpdateQuery}
                        isEditQuery={isEditQuery}
                        closePopup={setOpenSaveQueryPopup}
                        queryId={editqueryId}
                        editqueryName={editqueryName}
                        filterQueryJSON={campaignById.campaign?.filters}
                    />
                )}
                <div style={{ marginTop: '15px' }}>
                    {
                        !renderViewResults ?
                            (loading) ?
                                <div className='spa-progressring'><ProgressRing label='Loading...' indeterminate></ProgressRing></div> :
                                <>
                                    <Select
                                        value={(selectedDataset ? selectedDataset.config.workflowName : '')}
                                        slot='action'
                                        fixed-placement
                                        className='Text-fields'
                                        placeholder='Select Dataset'
                                        name='inputViewName'
                                        onHeChange={handleInputViewNameChange}
                                        validationMessage='Please select Model / DataSet'
                                        required
                                    >
                                        <Option
                                            key="create-new-dataset"
                                            role="menuitemcheckbox"
                                            value="create-new-dataset"
                                            onClick={createNewDataset}
                                        >
                                            <Icon slot="start" name="add"></Icon>
                                            Create New Dataset
                                        </Option>
                                        {workflowsBySubKey.list?.filter(filterDataSet).map((item) => (
                                        <Option
                                            key={item.config.workflowId}
                                            role='menuitemcheckbox'
                                            value={JSON.stringify(item)}
                                            disabled={item.status === 'InProgress'}
                                            title={item.status === 'InProgress' ? 'Dataset publish is in progress.Please wait for sometime.' : item.config.workflowName}
                                        >
                                            {item.config.workflowName}
                                        </Option>
                                        ))}
                                    </Select>
                                    { showQB ?
                                        <></> :
                                        (loadingQB ? 
                                            <div className='spa-progressring'>
                                                <ProgressRing label='Loading Query Builder...' indeterminate></ProgressRing>
                                            </div>
                                            : 
                                            <FilterQuery
                                            attributesList={attributesList}
                                            inputView={workflowName}
                                            currentJsonTree={currentJSONTreeItem}
                                            currentQuery={''} // TODO ADD
                                            selectStatement={selectStatement}
                                            setSelectStatement={setSelectStatement}
                                            state={state}
                                            setState={setState}
                                    /> )}
                                </>:
                            <ViewQueryResults
                                setSelectedRows={setSelectedRows}
                                setRenderViewResults={setRenderViewResults}
                                handlePageChange={handlePageChange}
                                getAddAllCount={getAddAllCount}
                            />
                    }
                </div>
                </>
            }
            </Card>

            <Dialog heading="Add all" open={isAllRecordDialogOpen} onHeAfterHide={() => setIsAllRecordDialogOpen(false)}>
                <p>Would you like to add all {filteredCampaign.totalRowCount > 0 ? filteredCampaign.totalRowCount : ''} accounts to cart ?</p>
                <Button slot="footer" appearance="primary" onClick={() => {
                    setIsAllRecordDialogOpen(false);
                    addAllRecordsToCart();
                }}>Ok</Button>
                <Button slot="footer" onClick={() => setIsAllRecordDialogOpen(false)}>
                    Close
                </Button>
            </Dialog>

            {openCreateNewDataset && (
                <FileUploadPopUp openCreateNewDataset={openCreateNewDataset}  setOpenCreateNewDataset={setOpenCreateNewDataset} />
            )} 
        </>
    )
};

export const QueryBuilder = withErrorBoundary('Campaign: Query Builder', QueryBuilderC);

