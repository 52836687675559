import { createAsyncThunk } from "@reduxjs/toolkit"
import { RootState } from "../root-redux/RootState";
import { getRequest } from "./utils/GetRequest";
import { postRequest, postRequestFileUpload } from "./utils/PostRequest";
import { BulkReassign } from "../model/SPAModels/BulkReassign.Model";

export const getBulkReassignmentList = createAsyncThunk<any, void, { state: RootState }>('reassignment/getReassignmentsList',async (_, {getState, rejectWithValue})  => {
      const selectedEnvItem  = getState().env.selectedEnvItem;
      const response = await getRequest<any>(`${selectedEnvItem}/fileupload/getReassignmentRequests`);
      return response.data as BulkReassign[];
});

export const getAccountId = createAsyncThunk<any, void, { state: RootState }>('reassignment/getlocalaccountid', async (_, {getState, dispatch, rejectWithValue}) => {
    return getState().userDetails.localAccountId;
});

export const postReassignmentRequest = createAsyncThunk<any, BulkReassign, { state: RootState }>('reassignment/postReassignmentRequest', async (reassign, {getState}) => {
    const selectedEnvItem  = getState().env.selectedEnvItem;
    const response = await postRequest<BulkReassign, BulkReassign>(`${selectedEnvItem}/fileupload/addreassignmentrequest`, reassign);
    return response.status;
});

export const uploadReassignmentFile = createAsyncThunk<any, any, { state: RootState}>('reassignment/uploadReassignment', async ({payload}, {getState, dispatch}) => {
    try{
      const selectedEnvItem  = getState().env.selectedEnvItem;  
      const response = await postRequestFileUpload<any, any>(`${selectedEnvItem}/fileupload/uploadReassignment`, payload);
      return response.data
    }
    catch(e){
      console.log(e);
    }
});

export const insertReassignmentData = createAsyncThunk<any, any, { state: RootState}>('reassignment/insertReassignmentData', async ({payload}, {getState, dispatch}) => {
  try{
    const selectedEnvItem  = getState().env.selectedEnvItem;  
    const response = await postRequestFileUpload<any, any>(`${selectedEnvItem}/fileupload/insertReassignmentData`, payload);
    return response.data
  }
  catch(e){
    console.log(e);
  }
});

export const runBulkReassignmentJob = createAsyncThunk<any, any, { state: RootState }>('/reassignment/runAdb', async (reassignId ,{getState}) => {
  const env = getState().env.selectedEnvItem;
  var response = await getRequest<string>(`${env}/fileupload/${reassignId}/runAdb`);
  return response?.data;
});
  