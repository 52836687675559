import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RequestStatus } from "../../../model/ServiceRequestStatus.model"
import { getUserSalesRoles } from "../../../services/Taxonomy/UserRoles.api";
import { SPA_ADMIN_ROLE, SPA_DRI_ROLE, SPA_REVIEWER_ROLE } from "../SPAConstants";

export interface SystemUserDetail {
    fullname: string | undefined
    domainname: string | undefined
    area: string | undefined
    region: string | undefined
    subRegion: string | undefined
}

export interface UserRolesState {
    userDetails: SystemUserDetail,
    isApprover: boolean,
    isSpaUser: boolean,
    isSpaAdmin: boolean,
    isSpaAreaPickerOpen: boolean,
    requestStatus: RequestStatus,
    error: string | undefined
}

const initialState: UserRolesState = {
    userDetails: {
        fullname: undefined,
        domainname: undefined,
        area: undefined,
        region: undefined,
        subRegion: undefined
    },
    isSpaAreaPickerOpen: false,
    isApprover: false,
    isSpaUser: false,
    isSpaAdmin: false,
    requestStatus: RequestStatus.idle,
    error: undefined
}

export const UserSalesRolesSlice = createSlice({
    name: 'userRole',
    initialState,
    reducers: {
        setIsApproverStatus: (state, action: PayloadAction<boolean>) => {
            state.isApprover = action.payload;
        },
        setImpersonateArea: (state, action: PayloadAction<string | undefined>) => {
            state.userDetails.area = action.payload;
            const userSalesRoles = JSON.parse(localStorage.getItem('userSalesRoles') || '{}');
            userSalesRoles['area'] = action.payload;
            localStorage.setItem('userSalesRoles', JSON.stringify(userSalesRoles));
        },
        setIsSpaUser: (state, action: PayloadAction<boolean>) => {
            state.isSpaUser = action.payload;
        },
        setIsSpaAreaPickerOpen: (state, action: PayloadAction<boolean>) => {
            state.isSpaAreaPickerOpen = action.payload;
        },
        setIsSpaAdmin: (state, action: PayloadAction<boolean>) => {
            state.isSpaAdmin = state.isSpaAdmin || action.payload;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(getUserSalesRoles.pending, (state, _) => {
                state.requestStatus = RequestStatus.loading;
                state.error = undefined;
            })
            .addCase(getUserSalesRoles.fulfilled, (state, action) => {
                const userSalesRoles = {
                    fullname: action.payload["fullname"],
                    domainname: action.payload["domainname"],
                    area: state.userDetails.area || action.payload["msp_areaname"],
                    region: action.payload["msp_regionname"],
                    subRegion: action.payload["msp_subregionname"]
                } as SystemUserDetail;
                localStorage.setItem('userSalesRoles', JSON.stringify(userSalesRoles));
                state.userDetails = userSalesRoles;
                state.isApprover = (action.payload["securityRoles"] || []).includes(SPA_REVIEWER_ROLE);
                state.isSpaUser = (action.payload["securityRoles"] || []).includes(SPA_DRI_ROLE);
                state.isSpaAdmin = state.isSpaAdmin || (action.payload["securityRoles"] || []).includes(SPA_ADMIN_ROLE);
                state.requestStatus = RequestStatus.succeeded;
                state.error = undefined;
            })
            .addCase(getUserSalesRoles.rejected, (state, action) => {
                state.requestStatus = RequestStatus.failed;
                state.error = action.error.message || state.error;
            })
    }
});

export const {
    setIsApproverStatus,
    setImpersonateArea,
    setIsSpaUser,
    setIsSpaAdmin,
    setIsSpaAreaPickerOpen
} = UserSalesRolesSlice.actions;

export const UserSalesRolesReducer = UserSalesRolesSlice.reducer