export enum NavMenuItemType {
    Home = 0,
    WorkFlows = 1 << 0,
    MergeRules = 1 << 1,
    APIConfigurations = 1 << 2,
    Connections = 1 << 3,
    TenantAdmin = 1 << 4,
    Users = 1 << 5,
    Reports = 1 << 6,
    MergeRuleSet = 2 << 5,
    Configuration = 2 << 7,
    Campaign = 2 << 8,
    Dataset = 2 << 9,
    Reassignment = 2 << 10
}